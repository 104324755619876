import { Component, ElementRef, inject } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Country, ICountry, State } from 'country-state-city';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject, takeUntil, map, debounceTime, distinctUntilChanged, switchMap, Observable } from 'rxjs';
import {
  CCreateCandidate,
  listContracts,
  months,
} from 'src/app/core/models/candidat';
import {
  checkRequiredInputs,
  emailExist,
  fileTypeExtention,
  invalidSelectedDate,
  linkdinExist,
  maxSizeFile,
  noThingChanged,
  phoneNumberExist,
  serverError,
  succesdisassociateConsultant,
  successEditProfile,
} from 'src/app/core/models/messages';
import {
  linkedInRegExp,
  patternEmail,
  patternInfoUser,
} from 'src/app/core/models/pattern';
import { CandidatService } from 'src/app/core/services/candidat/candidat.service';
import { environment } from 'src/environments/environment';
import {
  SearchCountryField,
  CountryISO,
  PhoneNumberFormat,
} from 'ngx-intl-tel-input';
import { ModuleService } from 'src/app/core/services/modules/module.service';
import Swal from 'sweetalert2';
import { TenderService } from 'src/app/core/services/tender/tender.service';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { MatChipEditedEvent, MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import * as saveAs from 'file-saver';
import { PaysService } from 'src/app/shared/services/pays.service';
import { PrimeNGConfig } from 'primeng/api';
import { DatePipe } from '@angular/common';
import { CvType } from 'src/app/core/models/enums/cv-type.enum';
export interface addSkill {
  name: string;
}
@Component({
  selector: 'app-update-candidat',
  templateUrl: './update-candidat.component.html',
  styleUrls: ['./update-candidat.component.css'],
})
export class UpdateCandidatComponent {
  

  toolbarOptions = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
      ['blockquote', 'code-block'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }], ['task-list'],
      [{ 'script': 'sub' }, { 'script': 'super' }],      // superscript/subscript
      [{ 'indent': '-1' }, { 'indent': '+1' }],          // outdent/indent
      [{ 'direction': 'rtl' }],                         // text direction
      [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
      [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
      [{ 'font': [] }],
      [{ 'align': [] }],
      ['clean']                                         // remove formatting button
    ]
    
    
  }; 
  searchTerm: string = '';
  isAnnuler: any = false;
  arrSkilles: any[] = [];
  date: Date[] = [];
  certificateDates: Date[] = [];
  endDates: Date[] = [];
  today=new Date();
  competences = [
    { id: 0, name: 'HTML' },
    { id: 1, name: 'CSS' },
    { id: 2, name: 'Java' },
    { id: 3, name: 'Javascript' },
    { id: 4, name: 'Angular' },
    { id: 5, name: 'Node' },
  ];
  disponibilite = [
    { id: 1, name: 'ASAP', value: '1' },
    { id: 2, name: 'Sous 1 mois', value: '2' },
    { id: 3, name: '2 mois', value: '3' },
    { id: 4, name: '3 mois', value: '4' },
    { id: 5, name: '4 mois', value: '5' },
    { id: 6, name: '5 mois', value: '6' },
  ];
  soft = [
    { id: 1, name: 'La créativité', value: 'La créativité' },
    { id: 2, name: 'La flexibilité', value: 'La flexibilité' },
    { id: 3, name: 'La communication', value: 'La communication' },
    { id: 4, name: 'La curiosité', value: 'La curiosité' },
    { id: 5, name: 'Le travail en équipe', value: 'Le travail en équipe' },
    { id: 6, name: 'La capacité d"adaptation', value: 'La capacité d"adaptation' },
    { id: 7, name: 'La gestion du stress', value: 'La gestion du stress' },
    { id: 8, name: 'L"esprit critique', value: 'L"esprit critique' },
    { id: 9, name: 'Leadership/Management', value: 'Leadership/Management' },
    { id: 10, name: 'La motivation', value: 'La motivation' },
    { id: 11, name: 'La négociation', value: 'La négociation' },
    { id: 12, name: 'Le jugement et la prise de décision', value: 'Le jugement et la prise de décision' },
    { id: 13, name: 'L"autonomie', value: 'L\'autonomie' },
    { id: 14, name: 'La coopération', value: 'La coopération' },
  ];
  languages = [
    { id: 1, name: 'Abkhaze',isFiltered:false },
    { id: 2, name: 'Afrikaans',isFiltered:false },
    { id: 3, name: 'Albanais',isFiltered:false},
    { id: 4, name: 'Allemand',isFiltered:false},
    { id: 5, name: 'Amharique',isFiltered:false},
    { id: 6, name: 'Anglais',isFiltered:false},
    { id: 7, name: 'Arabe',isFiltered:false},
    { id: 8, name: 'Arménien',isFiltered:false},
    { id: 9, name: 'Assamais',isFiltered:false},
    { id: 10, name: 'Aymara',isFiltered:false},
    { id: 11, name: 'Azéri',isFiltered:false},
    { id: 12, name: 'Basque',isFiltered:false},
    { id: 13, name: 'Bengali',isFiltered:false},
    { id: 14, name: 'Biélorusse',isFiltered:false},
    { id: 15, name: 'Birman',isFiltered:false},
    { id: 16, name: 'Bosniaque',isFiltered:false},
    { id: 17, name: 'Bulgare',isFiltered:false},
    { id: 18, name: 'Catalan',isFiltered:false},
    { id: 19, name: 'Cebuano',isFiltered:false},
    { id: 20, name: 'Chewa',isFiltered:false},
    { id: 21, name: 'Chichewa',isFiltered:false},
    { id: 22, name: 'Chinois',isFiltered:false},
    { id: 23, name: 'Coréen',isFiltered:false},
    { id: 24, name: 'Corse',isFiltered:false},
    { id: 25, name: 'Croate',isFiltered:false},
    { id: 26, name: 'Danois',isFiltered:false},
    { id: 27, name: 'Dzongkha',isFiltered:false},
    { id: 28, name: 'Espagnol',isFiltered:false},
    { id: 29, name: 'Estonien',isFiltered:false},
    { id: 30, name: 'Finnois',isFiltered:false},
    { id: 31, name: 'Flamand',isFiltered:false},
    { id: 32, name: 'Français',isFiltered:false},
    { id: 33, name: 'Frison',isFiltered:false},
    { id: 34, name: 'Gaélique',isFiltered:false},
    { id: 35, name: 'Galicien',isFiltered:false},
    { id: 36, name: 'Géorgien',isFiltered:false},
    { id: 37, name: 'Grec',isFiltered:false},
    { id: 38, name: 'Groenlandais',isFiltered:false},
    { id: 39, name: 'Gujarati',isFiltered:false},
    { id: 40, name: 'Haoussa',isFiltered:false},
    { id: 41, name: 'Hébreu',isFiltered:false},
    { id: 42, name: 'Hindi',isFiltered:false},
    { id: 43, name: 'Hmong',isFiltered:false},
    { id: 44, name: 'Hongrois',isFiltered:false},
    { id: 45, name: 'Ibo',isFiltered:false},
    { id: 46, name: 'Islandais',isFiltered:false},
    { id: 47, name: 'Indonésien',isFiltered:false},
    { id: 48, name: 'Inuktitut',isFiltered:false},
    { id: 49, name: 'Italien',isFiltered:false},
    { id: 50, name: 'Japonais',isFiltered:false},
    { id: 51, name: 'Javanais',isFiltered:false},
    { id: 52, name: 'Kannada',isFiltered:false},
    { id: 53, name: 'Kashmiri',isFiltered:false},
    { id: 54, name: 'Kazakh',isFiltered:false},
    { id: 55, name: 'Khmer',isFiltered:false},
    { id: 56, name: 'Kinyarwanda',isFiltered:false},
    { id: 57, name: 'Kirghize',isFiltered:false},
    { id: 58, name: 'Kirundi',isFiltered:false},
    { id: 59, name: 'Kurde',isFiltered:false},
    { id: 60, name: 'Laotien',isFiltered:false},
    { id: 61, name: 'Latin',isFiltered:false},
    { id: 62, name: 'Letton' ,isFiltered:false},
    { id: 63, name: 'Lituanien' ,isFiltered:false},
    { id: 64, name: 'Luxembourgeois' ,isFiltered:false},
    { id: 65, name: 'Macédonien' ,isFiltered:false},
    { id: 66, name: 'Malais' ,isFiltered:false},
    { id: 67, name: 'Malayalam' ,isFiltered:false},
    { id: 68, name: 'Malgache' ,isFiltered:false},
    { id: 69, name: 'Maltais' ,isFiltered:false},
    { id: 70, name: 'Maori' ,isFiltered:false},
    { id: 71, name: 'Marathi',isFiltered:false },
    { id: 72, name: 'Mongol' ,isFiltered:false},
    { id: 73, name: 'Néerlandais' ,isFiltered:false},
    { id: 74, name: 'Népalais' ,isFiltered:false},
    { id: 75, name: 'Norvégien' ,isFiltered:false},
    { id: 76, name: 'Ouzbek' ,isFiltered:false},
    { id: 77, name: 'Pachtou',isFiltered:false },
    { id: 78, name: 'Papiamento' ,isFiltered:false},
    { id: 79, name: 'Pashto' ,isFiltered:false},
    { id: 80, name: 'Persan (farsi)' ,isFiltered:false},
    { id: 81, name: 'Polonais' ,isFiltered:false},
    { id: 82, name: 'Portugais' ,isFiltered:false},
    { id: 83, name: 'Punjabi' ,isFiltered:false},
    { id: 84, name: 'Quechua',isFiltered:false },
    { id: 85, name: 'Roumain',isFiltered:false },
    { id: 86, name: 'Russe' ,isFiltered:false},
    { id: 87, name: 'Somali' ,isFiltered:false},
    { id: 88, name: 'Samoan' ,isFiltered:false},
    { id: 89, name: 'Sanskrit',isFiltered:false },
    { id: 90, name: 'Serbe',isFiltered:false },
    { id: 91, name: 'Sesotho' ,isFiltered:false},
    { id: 92, name: 'Shona',isFiltered:false },
    { id: 93, name: 'Sindhi' ,isFiltered:false},
    { id: 94, name: 'Slovaque',isFiltered:false },
    { id: 95, name: 'Slovène',isFiltered:false },
    { id: 96, name: 'Sotho',isFiltered:false },
    { id: 97, name: 'Soundanais',isFiltered:false },
    { id: 98, name: 'Suédois' ,isFiltered:false},
    { id: 99, name: 'Swahili' ,isFiltered:false},
    { id: 100, name: 'Tadjik' ,isFiltered:false},
  ];
  isFormatCompetenceAdmin: boolean = false;
  mention = [
    { id: 1, name: 'Assez Bien' },
    { id: 2, name: 'Bien' },
    { id: 3, name: 'Très Bien' },
    { id: 4, name: 'Excellent' },
  ];
  
  listSkills: any[] = [];
  updateSkills: any[] = [];
  CreateCandidate = new CCreateCandidate();
  // candidateForm!:FormGroup
  addOnBlur = true;
  readonly separatorKeysCodes = [ENTER, COMMA] as const;
  addSkills: addSkill[] = [];
  skillsTextArraytext: any[] = [];
  mobilities: any[] = [];
  countries: any[] = [];
  announcer = inject(LiveAnnouncer);
  daysDifference: number = 0;
  file: any;
  selectedImage: any;
  PhoneNumberFormat = PhoneNumberFormat;
  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [
    CountryISO.UnitedStates,
    CountryISO.UnitedKingdom,
  ];
  separateDialCode: boolean = false;
  selectedCountryCode: string = 'FR';
  categories: any = [];
  listCategories: any = [];
  colors = ['#FBF2E9', '#E8F6FC', '#FDEFEE', '#FFF3D7', '#FBF2E9'];
  selectedSubcategoryIds: any[] = [];
  selectedSubcategories: string[] = [];
  skillsList: { name_FR: string; id: any }[] = [];
  skillsCandidate: any;
  skillsCandidat: any = [];
  idSubCategory: any;
  deteleCV: any;
  // SkillsStars: number[] = [];
  SkillsStars: number[][] = [];
  flattenedStars: number[] = [];
  clickedStars: number[][] = [];
  /* formData */
  formData = new FormData();
  /* form group */
  phoneForm!: FormGroup;
  ExperienceForm!: FormGroup;
  diplomeForm!: FormGroup;
  certificatForm!: FormGroup;
  langueForm!: FormGroup;
  candidateForm: FormGroup = this.createCandidateForm(new CCreateCandidate());
  /* numbers */
  itemsPerPage: number = 5;
  currentPage: number = 1;
  totalItems!: number;
  startIndex: number = 0;
  endIndex: number = 5;
  idCandidat: any;
  cvCandidat: any;
  tab = 1;
  /* object */
  selectedCandidate: any;
  /* arrays */
  searchLang$ = new Subject<string>();

  stateList: any = [];
  listMonths = months;
  listCantrat = listContracts;
  yearsList: number[] = [];
  /* string */
  url: string = environment.baseUrl + '/api';
  fileName: string = '';
  selectedAvailability: string = '0';
  showSkills: string = 'info';
  photo: any;
  /* boolean */
  CheckedMission: any;
  maxSubSkills: number = 0;
  _afficherBloc: boolean = false;
  _afficherBlocExperience: boolean = false;
  _afficherBlocLangue: boolean = false;
  _afficherBlocCertificat: boolean = false;
  pagination : boolean =false;

  existCv: boolean = false;
  paysList: ICountry[] = [];
  experience: boolean = false;
  /* unsubscription */
  private unsubscribeAll: Subject<void> = new Subject();
  viewExperiance: boolean = true;
  submittedCertificats: boolean[] = []; // Track submission state for each certificate
  submittedDeplomes: boolean[] = []; // Track submission state for each diploma
  submittedLanguages: boolean[] = []; // Track submission state for each language
  filteredLanguages$!: Observable<any[]>;
  currentDate: string;
  minDate: any;
  cvTypeEnum= CvType;
  constructor(
    private formBuilder: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private candidatService: CandidatService,
    private jobRequestService: TenderService,
    private toastrService: ToastrService,
    private router: Router,
    private moduleService: ModuleService,
    private el: ElementRef,
    private paysServices: PaysService,
    private primengConfig: PrimeNGConfig,
    private datePipe: DatePipe

  ) {

    this.languages = this.languages.map((language: any) => ({
      ...language,
      isFiltered: false,
    }));

    const day = String(this.today.getDate()).padStart(2, "0");
    const month = String(this.today.getMonth() + 1).padStart(2, "0"); // Months are zero-based
    const year = this.today.getFullYear();
    this.currentDate = `${year}-${month}-${day}`;
     // Set the minimum date to 10 years ago, for example
     const minYear = 1990;
     this.minDate = `${minYear}-${month}-${day}`;

    
  }

  ngOnInit(): void {
    this.primengConfig.setTranslation({
      dayNames: [
        'dimanche',
        'lundi',
        'mardi',
        'mercredi',
        'jeudi',
        'vendredi',
        'samedi',
      ],
      dayNamesShort: ['dim.', 'lun.', 'mar.', 'mer.', 'jeu.', 'ven.', 'sam.'],
      dayNamesMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
      monthNames: [
        'janvier',
        'février',
        'mars',
        'avril',
        'mai',
        'juin',
        'juillet',
        'août',
        'septembre',
        'octobre',
        'novembre',
        'décembre',
      ],
      monthNamesShort: [
        'janv.',
        'févr.',
        'mars',
        'avr.',
        'mai',
        'juin',
        'juil.',
        'août',
        'sept.',
        'oct.',
        'nov.',
        'déc.',
      ],
      today: "Aujourd'hui",
      clear: 'Effacer',
    });
    this.paysList = this.paysServices.getAllCountries();
    this.getIdCandidat();
    this.getListModules();
    this.getSkills();
    this.getYearsList();
    this.getListSkills();
    this.phoneForm = new FormGroup({
      phone: new FormControl('', [Validators.required]),
    });
    this.CreateCandidate = new CCreateCandidate();
    this.filteredLanguages$ = this.searchLang$.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap((term) => this.searchLanguages(term))
    );
    // this.experienceText = this.getExperienceText();
    const experienceStored = localStorage.getItem('experience');
    this.experience = experienceStored ? JSON.parse(experienceStored) : false;

  }
  onSearch(term: any): void {
    console.log('term  : ', term.term);
    this.searchTerm = term.term;
  }

  getYearsList() {
    const currentYear = new Date().getFullYear();
    const startYear = 1990;
    for (let year = currentYear; year >= startYear; year--) {
      this.yearsList.push(year);
    }
  }
  /* get candidate id from route */
  getIdCandidat() {
    this.activatedRoute.paramMap.subscribe({
      next: (params: any) => {
        this.idCandidat = params.params['idCandidat'];
        this.getCandidateById();
      },
    });
  }
  searchLanguages(term: string): Observable<any[]> {
    return new Observable((observer) => {
      if (term) {
        const filtered = this.languages.filter((lang) =>
          lang.name.toLowerCase().includes(term.toLowerCase())
        );
        const sorted = filtered.sort((a, b) => {
          const aStartsWith = a.name
            .toLowerCase()
            .startsWith(term.toLowerCase());
          const bStartsWith = b.name
            .toLowerCase()
            .startsWith(term.toLowerCase());

          if (aStartsWith && !bStartsWith) {
            return -1;
          } else if (!aStartsWith && bStartsWith) {
            return 1;
          } else {
            return a.name.localeCompare(b.name);
          }
        });
        observer.next(sorted);
      } else {
        observer.next(this.languages);
      }
      observer.complete();
    });
  }
  /* get candidate by id */
  isexp: boolean = true;
  getCandidateById() {
    this.spinner.show();
    this.candidatService
      .getUserById(this.idCandidat)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res: any) => {
          this.selectedCandidate = res.data[0];
          if (this.selectedCandidate.ESN_name) {
            const esnCompany = {
              name: this.selectedCandidate.ESN_name,
              id: this.selectedCandidate.ESN_id,
            };
            this.companyOptions.push(esnCompany);
            this.selectedCompanyIds = this.companyOptions.map(option => option.id);
            this.candidateForm.get('selectedCompanyIds')?.setValue(this.selectedCompanyIds);

          }
          if (this.selectedCandidate.client_name) {
            const clientCompany = {
              name: this.selectedCandidate.client_name,
              id: this.selectedCandidate.client_id,
            };
            this.companyOptions.push(clientCompany);
            this.selectedCompanyIds = this.companyOptions.map(option => option.id);
            this.candidateForm.get('selectedCompanyIds')?.setValue(this.selectedCompanyIds);


          }
          if (this.candidateForm.get('date_first_experience')?.value != null) {
            this.experienceText = this.selectedCandidate.years_of_experience;
            if (this.experienceText === '0 ans') this.experienceText = '---';
            console.log(
              'experiencetext',
              this.selectedCandidate.years_of_experience
            );
            this.isexp = true;
          } else {
            this.isexp = false;
            console.log('experiencetext null');
          }
          const experiencesArray = this.candidateForm.get('prof_experience') as FormArray;
          experiencesArray.controls.forEach((experience) => {
          
            const endDate =  this.selectedCandidate.
            prof_experience[0]?.end_date;
            const todayDate = new Date().toISOString().split('T')[0]; 
            console.log("1",todayDate)
            console.log("2",endDate)
            if (endDate === todayDate) {
              this.checkeddatefin.push(true); 
               } else {
                 this.checkeddatefin.push(false); 
               }
          });
          console.log('selectedCandidate ', this.selectedCandidate);
          this.spinner.hide();
          this.CheckedMission = res.data[0].open_for_mission;
          this.candidateForm
            .get('open_for_mission')
            ?.setValue(this.CheckedMission);
          console.log(
            "open_for_mission: ['']",
            this.candidateForm.get('open_for_mission')?.value
          );
          if (typeof res.data.certificat == 'string')
            this.parseData('certificat');
          if (typeof res.data.langue_user == 'string')
            this.parseData('langue_user');
          if (typeof res.data.desired_contract == 'string')
            this.parseData('desired_contract');
          if (typeof res.data.diplome == 'string') this.parseData('diplome');
          if (typeof res.data.mobility == 'string') this.parseData('mobility');
          if (typeof res.data.prof_experience == 'string')
            this.parseData('prof_experience');
          if (typeof res.data.skills_text == 'string')
            this.parseData('skills_text');
          let skillsTextArray = this.selectedCandidate?.skills_text;
          this.skillsTextArraytext = this.selectedCandidate?.skills_text;
          for (let i = 0; i < skillsTextArray?.length; i++) {
            let newSkill: addSkill = { name: skillsTextArray[i] };
            this.addSkills.push(newSkill);
          }

          this.candidateForm = this.createCandidateForm(this.selectedCandidate);
          console.log('mobilities : ', this.selectedCandidate?.mobility);
          this.onCountryChange('add');
          // this.onMobilityChangeById(this.selectedCandidate?.mobility,this.setNullData('destination_country', this.selectedCandidate),"add")
          console.log(
            'selectedCandidateselectedCandidate',
            this.selectedCandidate
          );
          this.getDesirectContract();
          this.selectedAvailability = this.selectedCandidate.availability;
          this.phoneForm
            .get('phone')
            ?.setValue(this.candidateForm.get('phone_number')?.value);
          this.candidateForm.get('email')?.disable();
        },
        error: () => {
          this.spinner.hide();
        },
      });
  }
  parseData(name: string) {
    if (
      typeof this.selectedCandidate[name] == 'string' &&
      this.selectedCandidate[name] == 'null'
    ) {
      this.selectedCandidate[name] = [];
    } else {
      this.selectedCandidate[name] = JSON.parse(this.selectedCandidate[name]);
    }
  }
  getDesirectContract() {
    if (this.selectedCandidate.desired_contract.length) {
      for (const iterator of this.selectedCandidate.desired_contract) {
        this.listCantrat.forEach((option) => {
          if (option.id == iterator) option.checked = true;
        });
      }
    }
  }

  /**
  * Download CV.
  *  
  * @param {string} cvType - The type of CV to export (e.g., WEGESTU or ESN).
  * @param {number} isAnonymous - Whether to export an anonymous version of the CV (1 : not anonymous, 2 : anonymous) .
  * @returns {void}
  */
  downloadCV(cvType : string | undefined, isAnonymous: number){   
    if(cvType === this.cvTypeEnum.WEGESTU){
      this.exportCVCandidate(isAnonymous, null)
    }else{
      this.exportCVCandidate(isAnonymous, this.selectedCandidate?.ESN_id)
    }
}

// @TOFIX: duplicated
/**
* Exports the CV of a candidate based on the specified type and company ID.
*
* @param {number} type - The type of CV to export (e.g., 1 for standard, 2 for anonymous)
* @param {number | null} idCompany - The ID of the company for which the CV is being exported.
*                          Use `null` if no specific company is associated.
*/
exportCVCandidate(type: number, idCompany : number | null) {
  const extension = ".pdf"
  this.spinner.show()
  this.candidatService.exportCVCandidate(this.idCandidat, type, idCompany).subscribe({
  next: (res: any) => {
    this.spinner.hide();
    const blob = new Blob([res], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    let name = `${this.selectedCandidate?.last_name}-${this.selectedCandidate?.first_name}`
    if(idCompany){
      name = name.concat('-CvESN')
    }else{
      name = name.concat('-CvWegestu')
    }
    if(type === 2){
      name = name.concat('-Anonyme')
    }
    name = name.concat(extension)

    saveAs(blob, name);

      this.toastrService.success('CV téléchargé avec succès');
  },
  error: () => {
    this.spinner.hide();
    this.toastrService.error(serverError);
  },
  });
}

  updateAvailability(value: string) {
    this.selectedAvailability = value;
    this.candidateForm.get('availability')?.setValue(value);
  }
  updateOpenForMission(value: number) {
    const currentValue = this.candidateForm.get('open_for_mission')?.value;

    if (currentValue === value) {
      this.candidateForm.get('open_for_mission')?.setValue(null); // ou toute autre valeur par défaut
    } else {
      this.candidateForm.get('open_for_mission')?.setValue(value);
    }

    console.log(
      "open_for_mission: ['']",
      this.candidateForm.get('open_for_mission')?.value
    );
  }

  /* if value equal to null we should not display null in the input fields they should be empty */
  setNullData(name: string, data?: any) {
    if (data) {
      if (!data[name] || data[name] == 'null') {
        return '';
      } else {
        return data[name];
      }
    }
  }

  addDiplome() {
    const diplome = this.candidateForm.get('diplome') as FormArray;
    diplome.push(this.createFormDiplome());

    this.afficherBloc = true;

    console.log('affichierBolc', this.afficherBloc);
    this.submittedDeplomes.push(false)
    this.submittedDeplomes = this.submittedDeplomes.map(() => false); 

  }
  checkInputDiplome(event: any) {
    if (event) {
      this.afficherBloc = true;
    } else {
      this.afficherBloc = false;
    }
  }


  currentPeriod(startDate:any,endDate:any){
    const daysDifference = this.calculateDateDifference(startDate, endDate);
    const periodObject = this.calculateYearsMonthsDays(daysDifference - 1);
    const periodString = `${periodObject.years} ans, ${periodObject.months} mois, ${periodObject.days} jours`;
    console.log('periodString : ', periodString);
    return periodString;
  }
 


  addLangues() {
    const langue_user = this.candidateForm.get('langue_user') as FormArray;
    langue_user.push(this.createFormLangues());
    this.afficherBlocLangue = true;
    this.submittedLanguages.push(false)
    this.submittedLanguages = this.submittedLanguages.map(() => false);
  }
  addCertificats() {
    const certificat = this.candidateForm.get('certificat') as FormArray;
    certificat.push(this.createFormCertificate());
    this.afficherBlocCertificat = true;
    this.submittedCertificats.push(false)
    this.submittedCertificats = this.submittedCertificats.map(() => false); 

  }
  addExperiences() {
    const prof_experience = this.candidateForm.get(
      'prof_experience'
    ) as FormArray;
    this.checkeddatefin.push(true);
    console.log("prof_experience ",prof_experience);

    prof_experience.push(this.createFormExperience());
    this.afficherBlocExperience = true;
  }

  get diplomaControls(): AbstractControl<any, any>[] {
    return (this.candidateForm.get('diplome') as FormArray)?.controls || [];
  }
  // get skillsControls(): AbstractControl<any, any>[] {
  //   return (this.candidateForm.get('skills') as FormArray)?.controls || [];
  // }

  get certificatsControls(): AbstractControl<any, any>[] {
    return (this.candidateForm.get('certificat') as FormArray)?.controls || [];
  }
  get experiencesControls(): AbstractControl<any, any>[] {
    return (
      (this.candidateForm.get('prof_experience') as FormArray)?.controls || []
    );
  }

  removeDiploma(index: number) {
    Swal.fire({
      title: 'Êtes-vous sûr(e) ?',
      text: 'Êtes-vous sûr de vouloir supprimer ce diplôme ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Supprimer',
      cancelButtonText: 'Annuler',
    }).then((result) => {
      if (result.isConfirmed) {
    const diplome = this.candidateForm.get('diplome') as FormArray;
    diplome.removeAt(index);
    this.isfuturforma.splice(index, 1);
    this.isChosenDateValid1forma.splice(index, 1);
    this.invalid = false;
    this.candidateForm.get('diplome')?.updateValueAndValidity();
    this.updateSubmitButtonState1();
      
    } else if (
      result.dismiss === Swal.DismissReason.cancel ||
      result.dismiss === Swal.DismissReason.backdrop ||
      result.dismiss === Swal.DismissReason.esc ||
      result.dismiss === Swal.DismissReason.timer
    ) {
    }
    }); 
  }

  isButtonDisabled = false;
  removeCertificats(index: number) {

    Swal.fire({
      title: 'Êtes-vous sûr(e) ?',
      text: 'Êtes-vous sûr de vouloir supprimer ce Certificat ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Supprimer',
      cancelButtonText: 'Annuler',
    }).then((result) => {

      if (result.isConfirmed) {
       
       
    const certificat = this.candidateForm.get('certificat') as FormArray;
    certificat.removeAt(index);
    this.invalid = false; // Réinitialiser l'état d'invalidité

    // Recalculez l'état des erreurs des dates
    this.candidateForm.get('certificat')?.updateValueAndValidity();
    const certificatControls = this.candidateForm.get(
      'certificat'
    ) as FormArray;

    let hasInvalidDate = false;
    certificatControls.controls.forEach((control) => {
      const dateFormControl = control.get('date') as FormControl;
      if (dateFormControl?.hasError('invalidDate')) {
        hasInvalidDate = true;
      }
    });

    // Si aucune date n'est invalide, activez le bouton
    this.isButtonDisabled = hasInvalidDate || this.candidateForm.invalid;

    // Vous pouvez commenter ou enlever la ligne ci-dessous
    // this.toastrService.error('Veuillez saisir une date à partir de 1900.');

    this.updateSubmitButtonState(); // Mettre à jour l'état du bouton de soumission 

      
    } else if (
      result.dismiss === Swal.DismissReason.cancel ||
      result.dismiss === Swal.DismissReason.backdrop ||
      result.dismiss === Swal.DismissReason.esc ||
      result.dismiss === Swal.DismissReason.timer
    ) {
      ///this.handleCancel1();
    }

    }); 
 





  }
  updateSubmitButtonState() {
    const hasInvalidDate = this.candidateForm
      .get('certificat')
      ?.hasError('invalidDate');
    this.isButtonDisabled = hasInvalidDate || this.candidateForm.invalid;
  }
  updateSubmitButtonState1() {
    const diplomeArray = this.candidateForm.get('diplome') as FormArray;
    let hasInvalidDate = false;

    diplomeArray.controls.forEach((control, index) => {
      const dateFormControl = control.get('date') as FormControl;
      if (
        this.isfuturforma[index] ||
        dateFormControl?.hasError('invalidDate')
      ) {
        hasInvalidDate = true;
      }
    });

    this.isButtonDisabled = hasInvalidDate || this.candidateForm.invalid;
  }
  removeExperiences(index: number) {
    Swal.fire({
      title: 'Êtes-vous sûr(e) ?',
      text: 'Êtes-vous sûr de vouloir supprimer cette expérience ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Supprimer',
      cancelButtonText: 'Annuler',
    }).then((result) => {
      if (result.isConfirmed) {
        this.checkeddatefin=this.checkeddatefin.splice(index,1);
        const prof_experience = this.candidateForm.get(
          'prof_experience'
        ) as FormArray;
        prof_experience.removeAt(index);
    } else if (
      result.dismiss === Swal.DismissReason.cancel ||
      result.dismiss === Swal.DismissReason.backdrop ||
      result.dismiss === Swal.DismissReason.esc ||
      result.dismiss === Swal.DismissReason.timer
    ) {
    }
    }); 
  }
  removeLangue(index: number,langue:any) {
    Swal.fire({
      title: 'Êtes-vous sûr(e) ?',
      text: 'Êtes-vous sûr de vouloir supprimer ce Langue ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Supprimer',
      cancelButtonText: 'Annuler',
    }).then((result) => {
      if (result.isConfirmed) {
      
        console.log("before list of languages :  ",this.languages.length)

        if(this.languages.filter((item:any)=>item.name===langue.value.name).length>0) 
        this.languages.filter((item:any)=>item.name===langue.value.name)[0].isFiltered = false;
        const langue_user = this.candidateForm.get('langue_user') as FormArray;
        langue_user.removeAt(index);
    } else if (
      result.dismiss === Swal.DismissReason.cancel ||
      result.dismiss === Swal.DismissReason.backdrop ||
      result.dismiss === Swal.DismissReason.esc ||
      result.dismiss === Swal.DismissReason.timer
    ) {
    }
    });  
  }

  set afficherBloc(value: boolean) {
    this._afficherBloc = value;
    const diplomeArray = this.candidateForm.get('diplome') as FormArray;
    diplomeArray.controls.forEach((control) => {
      control.get('name')?.setValidators(value ? Validators.required : null);
      control.get('name')?.updateValueAndValidity();
    });
  }
  get afficherBloc(): boolean {
    return this._afficherBloc;
  }
  createFormDiplome(data?: any) {
    const form = this.formBuilder.group({
      name: [
        data ? data.name : '',
        [Validators.required]
      ],
      date: [
        data ? new Date(data.date).getFullYear().toString() : null,
      ],
      ecole: [data ? data.ecole : ''],
      mention: [data ? data.mention : null],
      user_id: this.idCandidat,
    });
    this.diplomeForm = form;
    return form;
  }
  set afficherBlocLangue(value: boolean) {
    this._afficherBloc = value;
    const langueArray = this.candidateForm.get('langue_user') as FormArray;
    langueArray.controls.forEach((control) => {
      control.get('name')?.setValidators(value ? Validators.required : null);
      control.get('name')?.updateValueAndValidity();
      control.get('stars')?.setValidators(value ? Validators.required : null);
      control.get('stars')?.updateValueAndValidity();
    });
  }

  get afficherBlocLangue(): boolean {
    return this._afficherBlocLangue;
  }

  createFormLangues(data?: any) {
    const form = this.formBuilder.group({
      name: [
        data ? data.name : null,
        this.afficherBlocLangue ? Validators.required : null,
      ],
      stars: [
        data ? data.stars : '',
        this.afficherBlocLangue ? Validators.required : null,
      ],
      user_id: this.idCandidat,
    });
    this.langueForm = form;
    return form;
  }

  // set afficherBlocCertificat(value: boolean) {
  //   this._afficherBlocCertificat = value;
  //   this.certificatForm.get('name')?.setValidators(value ? Validators.required : null);
  //   this.certificatForm.get('name')?.updateValueAndValidity();
  // }
  // get afficherBlocCertificat(): boolean {
  //   return this._afficherBlocCertificat;
  // }

  set afficherBlocCertificat(value: boolean) {
    this._afficherBlocCertificat = value;
    const certificatArray = this.candidateForm.get('certificat') as FormArray;
    certificatArray.controls.forEach((control) => {
      control.get('name')?.setValidators(value ? Validators.required : null);
      control.get('name')?.updateValueAndValidity();
    });
  }

  get afficherBlocCertificat(): boolean {
    return this._afficherBlocCertificat;
  }
  futurdate: boolean = true;

  createFormCertificate(data?: any) {
    const form = this.formBuilder.group({
      name: [
        data ? data.name : '',
        [Validators.required]
      ],
      date: [
        data ? new Date(data.date).getFullYear().toString() : null,
      ],
      skills: [data ? data.skills : []],
      end_date: [
        data ? new Date(data.end_date).getFullYear().toString() : null,
        //[Validators.required],
      ],
      user_id: this.idCandidat,
    });
    this.certificatForm = form;
    return form;
  }
  hasDateError(index: number) {
    const dateField = this.candidateForm.get('diplome.' + index + '.date');
    return dateField?.hasError('invalidDate') && dateField?.touched;
  }
  hasDateError1(index: number) {
    const dateField = this.candidateForm.get('certificat.' + index + '.date');
    return dateField?.hasError('invalidDate') && dateField?.touched;
  }
  isfutur: any = [];
  isChosenDateValid1: any = [];
  onInput(event: Event) {
    const input = event.target as HTMLInputElement;
    // Retirer tout caractère qui n'est pas un chiffre, un slash ou un espace
    input.value = input.value.replace(/[^0-9\/]/g, '');
  }
  
  selectDate1(i: any) {
    const today = new Date();
    console.log("today",today)
    const chosen = new Date(this.ExperienceForm.value.start_date);
    console.log("chosen",chosen)
    this.currentindex = i;
    this.isChosenDateValid1[i] = chosen > today;
    this.isfutur[i] = this.isChosenDateValid1[i];
    console.log('isfutur', this.isfutur[i]);
  }

  isfuturforma: any = [];
  isChosenDateValid1forma: any = [];
  selectDate1forma(i: any) {
    const today = new Date();
    const chosen = new Date(this.diplomeForm.value.date);
    this.currentindex = i;
    this.isChosenDateValid1forma[i] = chosen > today;
    this.isfuturforma[i] = this.isChosenDateValid1forma[i];
    this.updateSubmitButtonState1();
  }

  allDatesValid(): boolean {
    return this.isfuturforma.every((dateValid: any) => !dateValid);
  }
  onClose() {
    console.log('Dropdown closed');
    alert('close all ');
  }

  clearSelection(event: Event) {
    event.stopPropagation();
    this.skillsList = [];
  }

  isfutur2: any = [];
  isChosenDateValid2: any = [];
  // isfutur3:any=[]
  // isChosenDateValid3: any=[];
  isfutur3: any = [];
  isChosenDateValid3: any = [];

  currentindex: any;
  selectDate2(index: number): void {
    // const date = new Date(this.certificatsControls.at(index)?.get('date')?.value);
    // const end_date = new Date(this.certificatsControls.at(index)?.get('end_date')?.value);
    const date = new Date(
      this.certificatsControls.at(index)?.get('date')?.value
    );
    const end_date = new Date(
      this.certificatsControls.at(index)?.get('end_date')?.value
    );

    if (end_date > new Date()) {
      this.isChosenDateValid3[index] = false;
      this.isfutur3[index] = true;
    } else {
      this.isChosenDateValid3[index] = false;
      this.isfutur3[index] = false;
    }

    if (this.certificatsControls.at(index)?.get('end_date')?.value) {
      if (date > end_date) {
        this.isChosenDateValid2[index] = true;
        this.isfutur2[index] = true;
      } else {
        this.isChosenDateValid2[index] = false;
        this.isfutur2[index] = false;
      }

      if (end_date > new Date()) {
        this.isChosenDateValid3[index] = false;
        this.isfutur3[index] = true;
      } else {
        this.isChosenDateValid3[index] = false;
        this.isfutur3[index] = false;
      }
    } else {
      this.isChosenDateValid2[index] = false;
      this.isfutur2[index] = false;
    }

    if (this.certificatsControls.at(index)?.get('date')?.value) {
      if (date > end_date) {
        this.isChosenDateValid2[index] = true;
        this.isfutur2[index] = true;
      } else {
        this.isChosenDateValid2[index] = false;
        this.isfutur2[index] = false;
      }

      if (date > new Date()) {
        this.isChosenDateValid2[index] = false;
        this.isfutur2[index] = true;
      }
    } else {
      this.isChosenDateValid2[index] = false;
      this.isfutur2[index] = false;
    }
  }

  selectDate3(i: any) {
    const today = new Date();
    const chosen = new Date(this.ExperienceForm.value.start_date);
    this.currentindex = i;
    this.isChosenDateValid1[i] = chosen > today;
    if (this.isChosenDateValid1[i]) {
      this.isfutur[i] = true;
    } else {
      this.isfutur[i] = false;
    }
    this.checkeddatefin[i]=false;

    console.log('isfutur', this.isfutur[i]);
  }

  // selectDate2(i:any) {
  //   const date = this.certificatForm.value.date;
  //   const end_date =this.certificatForm.value.end_date;
  // this.currentindex=i
  //   this.isChosenDateValid2[i] = date > end_date;
  //   console.log("end date",end_date)
  //   if(this.isChosenDateValid2[i] && date && end_date) {
  //     this.isfutur2[i]=true
  //   } else{
  //     this.isfutur2[i]=false
  //   }
  //   console.log("isfutur2",this.isfutur2[i])
  // }
  dateValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const date = new Date(control.value);
      if (date.getFullYear() < 1900) {
        return { invalidDate: true };
      }
      return null;
    };
  }

  // futureDateValidator(control: AbstractControl): { [key: string]: boolean } | null {
  //   const date = control.get('date')?.value;
  //   const selectedDate = new Date(date);
  //   const currentDate = new Date();

  //   if (date > currentDate) {
  //     this.futurdate = false; // Mettez à jour futurdate ici
  //     return { 'futureDate': true };
  //   } else {
  //     this.futurdate = true; // Mettez à jour futurdate ici
  //   }
  //   console.log("futurdate", this.futurdate);
  //   return null;
  // }

  set afficherBlocExperience(value: boolean) {
    this._afficherBlocExperience = value;
    const certificatArray = this.candidateForm.get(
      'prof_experience'
    ) as FormArray;
    certificatArray.controls.forEach((control) => {
      control.get('poste')?.setValidators(value ? Validators.required : null);
      control.get('poste')?.updateValueAndValidity();
    });
  }
  get afficherBlocExperience(): boolean {
    return this._afficherBlocExperience;
  }
  createFormExperience(data?: any) {
    const isCurrentPosition = data ? data.current_position : '1';
    const form = this.formBuilder.group({
      poste: [
        data ? data.poste : '',
        this.afficherBlocExperience ? Validators.required : null,
      ],
      start_date: [
        data ? new Date(data.start_date) : null,[Validators.required]],
        end_date:[
          data ? (data.current_position === "0" ? new Date(data.end_date) : "") : null
        ],
      client: [data ? data.client : ''],
      period: [data ? data.current_position==="1"? this.currentPeriod(new Date(data.start_date),this.today) : data.period : `0 ans, 0 mois, 0 jours`],
      project: [data ? data.project : ''],
      description: [data ? data.description : ''],
      method: [data ? data.method : ''],
      technologies: [data ? data.technologies : []],
      tasks: [data ? data.tasks : ''],
      current_position: [data ? (data.current_position === "0" ? false : true) : false],
      user_id: this.idCandidat,
    });
     data ? data.start_date ? form.get('current_position')?.enable() : form.get('current_position')?.disable() : form.get('current_position')?.disable()  ;
    this.ExperienceForm = form;
    return form;
  }

  getTodayDate() {
    const today = new Date();
    return `${today.getFullYear()}-${(today.getMonth() + 1)
      .toString()
      .padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`;
  }

  createFormSkills(data?: any) {
    return this.formBuilder.group({
      sub_category_id: [data ? data.sub_category_id : null],
      stars: [data ? data.stars : ''],
      user_id: [data ? data.user_id : this.idCandidat],
    });
  }

  /* get form array diploma */
  get diplome() {
    return this.candidateForm.get('diplome') as FormArray;
  }
  /* get form array certificats */
  get certificats() {
    return this.candidateForm.get('certificat') as FormArray;
  }

  /* get form array experience */
  get prof_experience() {
    return this.candidateForm.get('prof_experience') as FormArray;
  }

  /* get form array langues */
  get langue_user() {
    return this.candidateForm.get('langue_user') as FormArray;
  }

  /* get form array skills */
  get skills() {
    return this.candidateForm.get('skills') as FormArray;
  }
  /* show confirmation disassociate Consultant  from ESN */
  showDisassociateConsultantConfirmation() {
    Swal.fire({
      title: 'Êtes-vous sûr de dissocier ce consultant?',
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: 'Oui',
      cancelButtonText: 'Non',
    }).then((result) => {
      if (result.isConfirmed) {
        this.disassociateConsultant();
        console.log('Selected Company IDs:', this.selectedCompanyIds);
      }
    });
  }

  /*disassociate Consultant  from ESN*/
  selectedCompanyIds: number[] = [];
  companyOptions:any[] = []
  dataDissocier:any

  onCompanyChange(event: any) {
    console.log('Change Event:', event);
        this.selectedCompanyIds = event.map((company: any) => company.id);
        console.log('Selected Company IDs:', this.selectedCompanyIds);
  }
  onRemove(event: any) {
    console.log('Removed company:', event);
    this.selectedCompanyIds = this.selectedCompanyIds.filter(id => id !== event);
    this.selectedCompanyIds = [...this.selectedCompanyIds];
  }
showSelect = true;

  disassociateConsultant() {
    let data = {
      user_id: this.selectedCandidate?.id,
      company_ids: this.selectedCompanyIds, 
    };
   this.dataDissocier=data
   this.companyOptions = []
   this.selectedCompanyIds= []
   this.showSelect = false;

  }
  dissocier(){
    this.candidatService
    .disassociateConsultant(this.dataDissocier)
    .pipe(takeUntil(this.unsubscribeAll))
    .subscribe({
      next: (res: any) => {
        this.toastrService.success('Consultant dissocié avec succès');
        this.getCandidateById()
      },
      error: () => {
        this.toastrService.error('Erreur du serveur');
      },
    });
  }
  cv_file!: string;
  fileExtention!: string;
  fileError!: string;
  /* upload CV */
  uploadCV(event: Event) {
    const fileInput = event.target as HTMLInputElement;
    const maxSize = 2 * 1024 * 1024;
    if (fileInput.files && fileInput.files[0]) {
      const allowedExtensions = ['.pdf', '.docx', '.doc'];
      const file = fileInput.files[0];
      const fileExtension = file.name
        .toLowerCase()
        .substr(file.name.lastIndexOf('.'));
      if (!allowedExtensions.includes(fileExtension)) {
        this.fileExtention = fileTypeExtention;
      } else if (file.size > maxSize) {
        this.fileError = maxSizeFile;
      } else {
        this.fileError = '';
        this.fileExtention = '';
        this.candidateForm.get('cv')?.setValue(file);
        this.setValidatorRequirements();
      }
      this.setValidatorRequirements();
    }
  }

  imageChanged: boolean = false;
  isImageError: boolean = false;

  uploadImage(event: any) {
    this.file = event.target.files[0];
    if (!this.isImage()) {
      this.isImageError = true;
      this.toastrService.error(
        "Le format du fichier n'est pas accepté.Veuillez choisir une photo"
      );

      return;
    }
    this.fileName = this.file.name;
    if (this.file) {
      const reader = new FileReader();
      reader.onload = () => {
        this.selectedImage = reader.result;
      };
      reader.readAsDataURL(this.file);
      this.candidateForm.get('image_url')?.setValue(this.file);
      this.candidateForm.get('image_url')?.markAsTouched();
      this.candidateForm.get('image_url')?.markAsDirty();
      this.imageChanged = true;
      this.isImageError = false; // Reset the error flag
    }
  }

  isImage(): boolean {
    const allowedExtensions = /(\.jpg|\.jpeg|\.png)$/i;
    return allowedExtensions.test(this.file.name);
  }

  /* change checkbox contrat */
  handleContractsCheckbox(event: any, optionValue: string) {
    // Recherche de l'élément correspondant à l'optionValue
    const item = this.listCantrat.find(
      (contract) => contract.value === optionValue
    );

    // Si l'élément est trouvé, mettez à jour sa propriété checked directement
    if (item) {
      item.checked = event.target.checked;
    }

    // Mettez à jour la valeur du formulaire en fonction des éléments cochés
    this.candidateForm
      .get('desired_contract')
      ?.setValue(this.getCheckedItems());
  }
  getCheckedItems() {
    const checkedItems = this.listCantrat?.filter((option) => option.checked);
    const checkedValues = checkedItems.map((item) => item.id);
    return checkedValues;
  }
  /* change english level */
  changeEnglishLevel(value: string) {
    this.candidateForm.get('English_level')?.setValue(value);
  }
  /* change frensh level */
  changeFrenshLevel(value: string) {
    this.candidateForm.get('Frensh_level')?.setValue(value);
  }

  scrollToError() {
    const errorMessageElement =
      this.el.nativeElement.querySelector('#error-message');
    if (errorMessageElement) {
      errorMessageElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  }
  /* create add candidat form */
   createCandidateForm(data?: CCreateCandidate) {
    return this.formBuilder.group({
      first_name: [
        data ? data.first_name : '',
        [Validators.required, Validators.pattern(patternInfoUser)],
      ],
      last_name: [
        data ? data.last_name : '',
        [Validators.required, Validators.pattern(patternInfoUser)],
      ],
      email: [data ? data.email : '', [Validators.email]],
      emailProESN: [
        data ? data.emailProESN : '',
        [Validators.pattern(patternEmail)],
      ],
      emailProClient: [
        data ? data.emailProClient : '',
        [Validators.pattern(patternEmail)],
      ],
      phone_number: [data ? data.phone_number : ''],
      destination_country: [
        data && data.destination_country != 'null'
          ? data.destination_country
          : [],
      ],
      mobility: [data && data.mobility != 'null' ? data.mobility : []],

      skills_text: [
        data && data.skills_text !== 'null' ? data.skills_text : '',
      ],
      availability: [data ? data.availability : null],
      date_first_experience: [
        data ? data.date_first_experience : ''
      ],
      current_country: [
        data && data.current_country != 'null' ? data.current_country : [],
      ],
      desired_contract: [data ? data.desired_contract : null],
      tjm: [data && data.tjm != 'null' && data.tjm != '0' ? data.tjm : ''],
      desired_salary: [
        data && data.desired_salary != 'null' && data.desired_salary != '0'
          ? data.desired_salary
          : '',
      ],
      linkedin_link: [this.setNullData('linkedin_link', data)],
      open_for_mission: [data ? data.open_for_mission : 0],
      cv: [data ? data.cv : null],
      image_url: [data ? data.image_url : ''],
      sub_category_id: [this.arrSkilles],
      skills: this.formBuilder.array(
        Array.isArray(data?.skills) && data?.skills.length
          ? data.skills.map((item: any) => this.createFormSkills(item))
          : []
      ),
      diplome: this.formBuilder.array(
        Array.isArray(data?.diplome) && data?.diplome.length
          ? data.diplome.map((item: any) => {
            this.submittedDeplomes.push(false);

           return this.createFormDiplome(item)
          }
          )
          : []
      ),
      certificat: this.formBuilder.array(
        Array.isArray(data?.certificat) && data?.certificat.length
          ? data.certificat.map((item: any) => {
            this.submittedCertificats.push(false);

           return this.createFormCertificate(item);
          })
          : []
      ),
      prof_experience: this.formBuilder.array(
        Array.isArray(data?.prof_experience) && data?.prof_experience.length
          ? data.prof_experience.map((item: any) =>
              this.createFormExperience(item)
            )
          : []
      ),
      langue_user: this.formBuilder.array(
        Array.isArray(data?.langue_user) && data?.langue_user.length
          ? data.langue_user.map((item: any) => {
            this.submittedLanguages.push(false);

            return  this.createFormLangues(item) ;
          }
            
            
           )
          : []
      ),
      desired_workstation: [
        data ? data.desired_workstation : '',
        [Validators.maxLength(100)],
      ],
      savoir_faire: [
        data ? data.savoir_faire : '',
        [Validators.maxLength(500)],
      ],
      softSkills: [data ? data.softSkills?.filter((item:any)=> item !== null)  : '', [Validators.maxLength(500)]],
      selectedCompanyIds: [this.selectedCompanyIds]
    });
  }

  ratings: { [key: string]: number } = {};
  addSkillsCandidat() {
    const selectedCategories =
      this.candidateForm.get('sub_category_id')?.value || [];
    console.log('selectedCategories', selectedCategories);

    const data = {
      id: this.idCandidat,
      sub_category_ids: selectedCategories,
    };
    this.candidatService.addSkillsForCandidate(data).subscribe({
      next: (res) => {
        //   this.spinner.hide();

        this.getSkills();
        this.getListSkills();
      },
      error: (err) => {
        /// this.spinner.hide();
      },
    });
  }

  restSkills() {
    this.addSkills = [];
    this.skillsTextArraytext = [];
  }
  clearAllSkills() {
    const subs: any[] = this.candidateForm.get('sub_category_id')?.value;
    const skills: any[] = this.candidateForm
      .get('skills')
      ?.value.map((item: any) => {
        return item.sub_category_id;
      });

    Swal.fire({
      title: 'Êtes-vous sûr(e) ?',
      text: 'Êtes-vous sûr de vouloir supprimer ces compétences ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Supprimer',
      cancelButtonText: 'Annuler',
    }).then((result) => {
      if (result.isConfirmed) {
        console.log('subs is  ', subs);
        console.log('skills is ', skills);
        const difference: any[] = subs.filter((x) => !skills.includes(x));
        console.log('difference is ', difference);

        console.log('skillsCandidat is ', this.skillsCandidat);

        this.skillsCandidat.forEach((item0: any) => {
          item0.sub_category = item0.sub_category.filter(
            (item: any) => !difference.includes(item.id)
          );

          return item0;
        });

        let lastskills: any[] = [];
        this.skillsCandidat.forEach((item0: any) => {
          if (item0.sub_category.length > 0) {
            lastskills.push(item0);
          }
        });

        this.skillsCandidat = lastskills;

        console.log('skillsCandidat is ', this.skillsCandidat);

        this.candidateForm
          .get('sub_category_id')
          ?.setValue(subs.filter((value) => skills.includes(value)));

        let names = this.updateSkills.filter((item: any) => item != '');

        console.log('updateSkills is ', this.updateSkills);

        this.addSkills = this.addSkills.filter(
          (item) => !names.includes(item.name)
        );

        console.log(' After AddSkills ? is : ', this.addSkills);

        this.removeSkills.length = 0;
        this.updateSkills.length = 0;
      }
      else if (
        result.dismiss === Swal.DismissReason.cancel ||
        result.dismiss === Swal.DismissReason.backdrop ||
        result.dismiss === Swal.DismissReason.esc ||
        result.dismiss === Swal.DismissReason.timer
      ) {
        this.handleCancel1();
      } 
    });
  }
  deleteSkillsCandidat() {
    Swal.fire({
      title: 'Êtes-vous sûr(e) ?',
      text: 'Êtes-vous sûr de vouloir réinitialiser ces compétences ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Supprimer',
      cancelButtonText: 'Annuler',
    }).then((result) => {
      if (result.isConfirmed) {
        this.candidatService
          .deleteAllSkills(this.idCandidat)
          .subscribe((res: any) => {
            console.log('response', res);
            this.toastrService.success(
              'Compétences supprimées avec succès',
              'Succès',
              {
                closeButton: true,
                progressBar: true,
                timeOut: 5000,
                extendedTimeOut: 2000,
              }
            );
            this.deletedSkills = [
              ...this.deletedSkills,
              ...Object.keys(this.ratings),
            ];
            const subCategoryIdControl =
              this.candidateForm.get('sub_category_id');
            if (subCategoryIdControl) {
              subCategoryIdControl.setValue(null);
            }
            this.skillsList = this.skillsList.filter(
              (item: any) => item !== this.idSubCategory
            );
            if (subCategoryIdControl) {
              subCategoryIdControl.setValue(this.arrSkilles);
            }
            this.restSkills();
            this.candidateForm.get('sub_category_id')?.reset();
            this.candidateForm.get('skills')?.reset();
            this.skillsCandidat.length = 0;
            this.removeSkills.length = 0;
            this.updateSkills.length = 0;
          });
      } else if (
        result.dismiss === Swal.DismissReason.cancel ||
        result.dismiss === Swal.DismissReason.backdrop ||
        result.dismiss === Swal.DismissReason.esc ||
        result.dismiss === Swal.DismissReason.timer
      ) {
        this.handleCancel1();
      }
    });
  }

  handleCancel1() {
    this.isAnnuler = false;
    const subCategoryIdControl: any[] =
      this.candidateForm.get('sub_category_id')?.value;
    const skills: any[] = this.candidateForm.get('skills')?.value;
    if (subCategoryIdControl.length === 0) {
      this.candidateForm.get('sub_category_id')?.setValue(
        skills
          .filter((item) => item.sub_category_id != null)
          .map((item) => item.sub_category_id)
          .concat(this.removeSkills)
      );
    } else {
      this.candidateForm
        .get('sub_category_id')
        ?.setValue(
          skills
            .filter((item) => item.sub_category_id != this.idSubCategory)
            .map((item) => item.sub_category_id)
        );
    }
  }

  selectItem(event: any) {
    console.log('value of blur : ', event);
  }
  idSkillsDelete: any;

  onRemoveSkill(event: any) {
    if (event.value) {
      if (event != undefined) {
        this.idSubCategory = event.value.id;
        this.deleteOnSkillCandidat(event.value.id);
      }
    } else if (event != undefined) {
      this.idSubCategory = event.id;
      this.deleteOnSkillCandidat(event.id);
    }
  }
  deleteOnSkillCandidat(subCategoryId: any) {
    let skills = this.candidateForm.get('skills')?.value;

    Swal.fire({
      title: 'Êtes-vous sûr(e) ?',
      text: 'Êtes-vous sûr de vouloir supprimer cette compétence ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Supprimer',
      cancelButtonText: 'Annuler',
    }).then((result) => {
      console.log('result.isConfirmed', result.isConfirmed);
      if (result.isConfirmed) {
        console.log('skills of isConfirmed  ', skills);
        console.log('skills of subCategoryId  ', subCategoryId);
        console.log(
          'length of skills : ',
          skills.filter((item: any) => item.sub_category_id === subCategoryId)
            .length
        );

        console.log('skills of skillsCandidat  ', this.skillsCandidat);

        if (
          skills.filter((item: any) => item.sub_category_id === subCategoryId)
            .length === 0
        ) {
          /* this.skillsCandidat = this.skillsCandidat.filter(
            (item: any) => item.sub_category_id === subCategoryId
          );*/
          this.toastrService.success(
            'Compétence supprimée avec succès',
            'Succès',
            {
              closeButton: true,
              progressBar: true,
              timeOut: 5000,
              extendedTimeOut: 2000,
            }
          );

          this.idSubCategory = subCategoryId;
          this.removeSkills=this.removeSkills.filter((item) => item != this.idSubCategory)

          this.deletedSkills.push(subCategoryId);
          this.skillsCandidat = this.skillsCandidat.filter((skill: any) => {
            skill.sub_category = skill.sub_category.filter(
              (item: any) => item.id != subCategoryId
            );
            return skill;
          });
          console.log('skills of skillsCandidat after  ', this.skillsCandidat);

         /* this.skillsList = this.skillsList.filter(
            (skill) => skill.id !== subCategoryId
          );*/
          const subCategoryIdControl =
            this.candidateForm.get('sub_category_id');
          if (subCategoryIdControl) {
            const selectedSkills = subCategoryIdControl.value;
            const updatedSelectedSkills = selectedSkills?.filter(
              (id: any) => id !== subCategoryId
            );
            console.log('updatedSelectedSkills : ', updatedSelectedSkills);
            subCategoryIdControl.setValue(updatedSelectedSkills);
          }
          this.isAnnuler = true;
        } else
          this.candidatService
            .deleteOnSkill(this.idCandidat, subCategoryId)
            .subscribe((res: any) => {
              console.log('response', res);
              this.toastrService.success(
                'Compétence supprimée avec succès',
                'Succès',
                {
                  closeButton: true,
                  progressBar: true,
                  timeOut: 5000,
                  extendedTimeOut: 2000,
                }
              );
              this.idSubCategory = subCategoryId;
              this.deletedSkills.push(subCategoryId);
              this.skillsCandidat = this.skillsCandidat.filter(
                (skill: any) => skill.id !== subCategoryId
              );

              this.skillsList = this.skillsList.filter(
                (skill) => skill.id !== subCategoryId
              );
              const subCategoryIdControl =
                this.candidateForm.get('sub_category_id');
              if (subCategoryIdControl) {
                const selectedSkills = subCategoryIdControl.value;
                const updatedSelectedSkills = selectedSkills?.filter(
                  (id: any) => id !== subCategoryId
                );
                console.log('updatedSelectedSkills : ', updatedSelectedSkills);
                subCategoryIdControl.setValue(updatedSelectedSkills);
              }
              this.isAnnuler = true;
              this.getSkills();
              this.getListSkills();
            });
      } else if (
        result.dismiss === Swal.DismissReason.cancel ||
        result.dismiss === Swal.DismissReason.backdrop ||
        result.dismiss === Swal.DismissReason.esc ||
        result.dismiss === Swal.DismissReason.timer
      ) {
        this.handleCancel(subCategoryId);
      }
    });
  }

  handleCancel(subCategoryId: any) {
    this.isAnnuler = false;
    const subCategoryIdControl: any[] =
      this.candidateForm.get('sub_category_id')?.value;
    subCategoryIdControl.push(subCategoryId);
    this.candidateForm.get('sub_category_id')?.setValue(subCategoryIdControl);
    console.log(
      'subCategoryIdControl of value ',
      this.candidateForm.get('sub_category_id')?.value
    );
  }



  calculateYearsMonthsDays(daysDifference: number): {
    years: number;
    months: number;
    days: number;
  } {
    const years = Math.floor(daysDifference / 365);
    const remainingDays = daysDifference % 365;
    const months = Math.floor(remainingDays / 30);
    const days = remainingDays % 30;
    return { years, months, days };
  }

  calculateDateDifference(start: string, end: string): number {
    if (start && end) {
      const startDate = new Date(start);
      const endDate = new Date(end);
      const timeDifference = endDate.getTime() - startDate.getTime();
      return Math.ceil(timeDifference / (1000 * 3600 * 24));
    }
    return 0;
  }

  areAllFieldsTouched(formGroup: FormGroup): boolean {
    return Object.values(formGroup.controls).every((control) => {
      if (control instanceof FormGroup) {
        return this.areAllFieldsTouched(control);
      } else {
        return control.touched;
      }
    });
  }
  downloadFile(typeFile?: string) {
    if (typeFile && typeFile != '')
      this.selectedCandidate.file_test =
        this.selectedCandidate.file_test.split('/')[1];
    this.spinner.show();
    const fileUrl = !typeFile
      ? `${this.url}/User/file/gallery_cvs/${this.selectedCandidate.cv}`
      : `${this.url}/ResponseCandidate/GetFile/${this.selectedCandidate.file_test}`;
    this.candidatService.downloadPDF(fileUrl).subscribe((blob) => {
      const fileURL = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = fileURL;
      a.download = !typeFile
        ? `${this.selectedCandidate.ID_user}_${
            this.selectedCandidate.first_name
          }_${
            this.selectedCandidate.last_name
          }_CV.${this.selectedCandidate!.cv.split('.').pop()}`
        : `${this.selectedCandidate.ID_user}_${
            this.selectedCandidate.first_name
          }_${
            this.selectedCandidate.last_name
          }_QCM.${this.selectedCandidate!.file_test.split('.').pop()}`;
      document.body.appendChild(a);
      a.click();
      this.spinner.hide();
    });
  }
  onItemClear(event: any) {
    this.deleteSkillsCandidat();
  }
  hasStartDateError(index: number){
    const startDateControl = this.candidateForm.get(`prof_experience.${index}.start_date`);
     startDateControl?.hasError('required') && startDateControl.touched;
  }
  setRating(i: any, j: any, stars: any) {
    console.log('skill_Id : ', i, 'sub_category_id : ', j, 'stars : ', stars);
    console.log('skillsCandidat1', this.skillsCandidat[i]);
    this.skillsCandidat[i].sub_category[j].skills[0].stars = stars;
    console.log('skillsCandidat2', this.skillsCandidat[i]);
  }
  addStarsToSkills(skillsCandidatAfterReview: any) {
    const skillsData = JSON.stringify(skillsCandidatAfterReview);
    this.candidatService
      .addStarsForSkillCandidate(skillsCandidatAfterReview)
      .subscribe({
        next: (res) => {
          console.log('Skills updated successfully', res);
          // this.toastrService.success('Skills updated successfully');
          this.removeSkills.length = 0;
          this.updateSkills.length = 0;
        },
        error: (err) => {
          console.error('Error updating skills', err);
          // this.toastrService.error('Error updating skills');
        },
      });
  }
  skillls: any[] = [];
  skillsAdd: any[] = [];

  invalid: boolean = false;
  
  onSubmit() {
    this.candidateForm.get('phone_number')?.setValue(this.candidateForm.get('phone_number')?.value ?? '');

    this.submittedCertificats = this.submittedCertificats.map(() => true);
    this.submittedDeplomes = this.submittedDeplomes.map(() => true);
    this.submittedLanguages = this.submittedLanguages.map(() => true);

    const formArrayCertificat = this.candidateForm.get('certificat') as FormArray;
    
    this.candidateForm.markAllAsTouched();
    this.candidateForm.markAsDirty();
    this.setValidatorRequirements();
    const profExperienceArray = this.candidateForm.get('prof_experience') as FormArray;
    if(profExperienceArray.length>0){
    const startDateValue = this.ExperienceForm.get('start_date')?.value;
    const endDateValue = this.ExperienceForm.get('end_date')?.value;
    if (this.isChosenDateValid) {
      return this.toastrService.error(invalidSelectedDate);
    }
      const posteValue = this.ExperienceForm.get('poste')?.value;
      if (posteValue === null || (posteValue === '' && startDateValue !== '')) {
        return this.toastrService.error(
          'Ajouter le titre de poste de votre experience'
        );
      }
  }

    // add deplome
  
    var tab_diplomaControls: any[] = [];
    this.diplomaControls.filter((control: AbstractControl) => {
      console.log("control of deplome ",control.value);
      if (control.value.name != '' ) {
        tab_diplomaControls.push(control.value);
       }
      // else if ((control.value.name === "" || control.value.name === '') && control.value.date != null){
      //   tab_diplomaControls.push(control.value);
      // } else if (control.value.name != "" && (control.value.date === null || control.value.date === "NaN")){
      //   tab_diplomaControls.push(control.value);
      // } 
    });

    const formArrayDiplome = this.candidateForm.get('diplome') as FormArray;
    formArrayDiplome.clear();
    tab_diplomaControls.forEach((item) => {
      formArrayDiplome.push(
        new FormGroup({
          date: new FormControl(item.date),
          name: new FormControl(item.name,Validators.required),
          ecole: new FormControl(item.ecole),
          mention: new FormControl(item.mention),
          user_id: new FormControl(item.user_id),
        })
      );
    });
    console.log("after tab_diplomaControls ",tab_diplomaControls);
    this.candidateForm.get('diplome')?.setValue(tab_diplomaControls)
    if(!formArrayDiplome.valid){

      console.log("formArray Diplome",formArrayDiplome.valid)
       return ;
     } 


    // add Certificat
    var tab_certificatsControls: any[] = [];
    this.certificatsControls.filter((control: AbstractControl) => {
      if (control.value.name != '' ) {
        tab_certificatsControls.push(control.value);
      }
    });
    formArrayCertificat.clear();
    tab_certificatsControls.forEach((item: any) => {
      formArrayCertificat.push(
        new FormGroup({
          name: new FormControl(item.name, Validators.required),
          date: new FormControl(item.date),
          skills: new FormControl(item.skills),
          end_date: new FormControl(item.end_date),
          user_id: new FormControl(item.user_id),
        })
      );
    });

    console.log("tab_certificatsControls ",tab_certificatsControls);
    this.candidateForm.get('certificat')?.setValue(tab_certificatsControls);


    if(!formArrayCertificat.valid){

     console.log("formArrayCertificat",formArrayCertificat.valid)
      return ;
    }
    
    
    var tab_languesControls: any[] = [];
    this.languesControls.filter((control: AbstractControl) => {
      if (control.value.name != null) {
        tab_languesControls.push(control.value);
      }
    });

    const formArrayLangue_user = this.candidateForm.get(
      'langue_user'
    ) as FormArray;
    formArrayLangue_user.clear();
    // Add the filtered controls back into the FormArray
    tab_languesControls.forEach((item: any) => {
      formArrayLangue_user.push(
        new FormGroup({
          name: new FormControl(item.name, Validators.required),
          stars: new FormControl(item.stars),
          user_id: new FormControl(item.user_id),
        })
      );
    });
    console.log("tab_languesControls ",tab_languesControls);

    this.candidateForm.get('langue_user')?.setValue(tab_languesControls);

    if(!formArrayLangue_user.valid){

      console.log("formArrayCertificat",formArrayLangue_user.valid)
       return ;
     }
     
     



const invalidExperience = profExperienceArray.controls.some((experience) => {
  const currentPosition = experience.get('current_position')?.value;
  const endDate = experience.get('end_date')?.value;
  return !currentPosition && !endDate;
});

if (invalidExperience) {
  this.toastrService.error('Veuillez cocher "poste actuel" ou saisir une date de fin pour chaque expérience.');
  return;
}
const invalidExperience1 = profExperienceArray.controls.some((experience) => {

  const start_date = experience.get('start_date')?.value;
  return  !start_date;
});

if (invalidExperience1) {
  this.toastrService.error('Veuillez saisir une date de début pour chaque expérience.');
  return;
}

   var tab_experiencesControls: any[] = [];
    this.experiencesControls.filter((control: AbstractControl) => {
      if (control.value.poste != '') {
        tab_experiencesControls.push(control.value);
      }
    });

    const formArrayExperience = this.candidateForm.get(
      'prof_experience'
    ) as FormArray;
    formArrayExperience.clear();
    tab_experiencesControls.forEach((item: any) => {
      console.log('currectposint is : ', item.current_position);

      formArrayExperience.push(
        new FormGroup({
          poste: new FormControl(item.poste),
          start_date: new FormControl(item.start_date),
          end_date: new FormControl(item.end_date),
          client: new FormControl(item.client),
          period: new FormControl(item.period),
          project: new FormControl(item.project),
          description: new FormControl(item.description),
          method: new FormControl(item.method),
          technologies: new FormControl(item.technologies),
          tasks: new FormControl(item.tasks),
          user_id: new FormControl(item.user_id),
          current_position: new FormControl(item.current_position),
        })
      );
    });

    this.candidateForm
      .get('prof_experience')
      ?.setValue(tab_experiencesControls);

    if (this.candidateForm.get('savoir_faire')?.hasError('maxlength')) {
      return this.toastrService.error(
        'Le champ Savoir-faire / Savoir-être ne peut pas dépasser 500 caractères.'
      );
    }
    if (this.candidateForm.get('desired_workstation')?.hasError('maxlength')) {
      return this.toastrService.error(
        'Le champ titre ne peut pas dépasser 100 caractères.'
      );
    }

    const isImageChanged = this.candidateForm.get('image_url')?.dirty;
    if (
      (this.candidateForm.touched && this.candidateForm.dirty) ||
      (this.phoneForm.touched && this.phoneForm.dirty) ||
      !this.file ||
      isImageChanged
    ) {
      this.candidateForm.markAllAsTouched();
      this.candidateForm.markAsDirty();
      const phone = this.phoneForm.get('phone')?.value;
      this.candidateForm.get('phone_number')?.setValue(phone?.internationalNumber ?? '');

      const invalidPhone = this.phoneForm.controls['phone'].invalid;
    
      if (this.fileExtention) {
        return this.toastrService.error(fileTypeExtention);
      }
      if (this.isImageError) {
        return this.toastrService.error(fileTypeExtention);
      }
      if (this.candidateForm.get('first_name')?.hasError('pattern')) {
        return this.toastrService.error('Le champ Nom est trop court.');
      }
      if (this.candidateForm.get('last_name')?.hasError('pattern')) {
        return this.toastrService.error('Le champ Prénom est trop court.');
      }
      const languesArray = this.candidateForm.get('langue_user') as FormArray;
    //  const languages = languesArray.value.map((langue: any) => langue.name);
      const languages = languesArray.value.map((langue: any) => {
        if (!langue.isFiltered) return langue.name;
      });
      const invalidStarsLanguage = languages.find(
        (lang: any) => lang && (lang.stars === '' || lang.stars === null)
      );
      const duplicateLanguage = languages.find(
        (value: any, index: any, array: any) => array.indexOf(value) !== index
      );
      if (invalidStarsLanguage) {
        return this.toastrService.error(
          'Le niveau de chaque langue doit être sélectionné.'
        );
      }
      if (duplicateLanguage) {
        return this.toastrService.error(
          'Vous ne pouvez pas ajouter deux langues avec le même nom.'
        );
      }

      if (this.isChosenDateValid) {
        return this.toastrService.error(invalidSelectedDate);
      }
      if (this.candidateForm.valid) {
        console.log(this.skillsCandidat.map((item: any) => item.sub_category));

        let listeCombinee: any = [];
        let skillsCandidatAfterReview: any = [];

        skillsCandidatAfterReview = this.skillsCandidat;
        skillsCandidatAfterReview
          .map((item: any) => item.sub_category)
          .forEach((liste: any) => {
            listeCombinee = listeCombinee.concat(liste);
          });

        skillsCandidatAfterReview = listeCombinee.map((item: any) => {
          return {
            sub_category_id: item.skills[0].sub_category_id,
            stars: item.skills[0].stars,
            user_id: this.idCandidat,
          };
        });
        const skills = this.candidateForm.get('skills')?.value;    
        this.skillsCandidat.forEach((item0: any) => {
          console.log('item0', item0);
          item0.sub_category.forEach((item1: any) => {
            console.log('item1', item1);
            item1.skills.forEach((item2: any) => {
              console.log('item2', item2);
              this.skillls.push(item2);
            });
          });
        });

        this.formData.append('skills', JSON.stringify(this.skillls));

        const diplome = this.candidateForm.get('diplome')?.value;
        const shouldSendEmptyArray =
          !diplome ||
          diplome.some(
            (diploma: any) => diploma.name === null || diploma.name === ''
          );
        this.formData.append(
          'diplome',
          shouldSendEmptyArray ? '[]' : JSON.stringify(diplome)
        );
        const certificat = this.candidateForm.get('certificat')?.value;
        const formattedCertificat = certificat.map((certificats: any) => {
          return {
            ...certificats,
            skills: Array.isArray(certificats.skills)
              ? certificats.skills
              : [certificats.skills],
          };
        });
        const isEmptyCertificat = formattedCertificat.every(
          (certificats: any) =>
            certificats.name === null || certificats.name === ''
        );
        const certificatToSend = isEmptyCertificat ? [] : formattedCertificat;
        this.formData.append('certificat', JSON.stringify(certificatToSend));
        const prof_experience =
          this.candidateForm.get('prof_experience')?.value;
        const formattedExperiences = prof_experience.map((experience: any) => {
          return {
            ...experience,
            technologies: Array.isArray(experience.technologies)
              ? experience.technologies
              : [experience.technologies],
          };
        });

        const isEmptyProfExperience = formattedExperiences.every(
          (experience: any) => experience.poste === ''
        );
 
        var profExperienceToSend = isEmptyProfExperience
          ? []
          : formattedExperiences;
          profExperienceToSend=profExperienceToSend.map((item:any)=> {
      item.current_position= item.current_position? "1" : "0"
      return item ;
   }) 
        this.formData.append(
          'prof_experience',
          JSON.stringify(profExperienceToSend)
        );

        const langue_user = this.candidateForm.get('langue_user')?.value;
        const shouldSendEmptyArrayLangues =
          !langue_user ||
          langue_user.some((langue: any) => langue.name === null);
        this.formData.append(
          'langue_user',
          shouldSendEmptyArrayLangues ? '[]' : JSON.stringify(langue_user)
        );
        const checkedItems = this.getCheckedItems();
        this.candidateForm.get('desired_contract')?.setValue(checkedItems);
        const desired_contract =
          this.candidateForm.get('desired_contract')?.value;
        if (Array.isArray(desired_contract) && desired_contract.length > 0) {
          for (const contrat of desired_contract) {
            this.formData.append('desired_contract[]', contrat);
          }
        } else {
          this.formData.append('desired_contract', JSON.stringify([]));
        }
        let mobility = this.candidateForm.get('mobility')?.value;

        if (Array.isArray(mobility) && mobility.length > 0) {
          for (const mob of mobility) {
            this.formData.append('mobility[]', mob);
          }
        } else {
          this.formData.append('mobility[]', '');
        }

        let destCountry = this.candidateForm.get('destination_country')?.value;

        if (Array.isArray(destCountry) && destCountry.length > 0) {
          console.log('destCountry isArray ', destCountry);

          for (const mob of destCountry) {
            this.formData.append('destination_country[]', mob);
          }
        } else {
          console.log('destCountry not isArray ', destCountry);

          console.error('destination_country is not an array');
          this.formData.append('destination_country[]', '');
        }

        const skillsText = this.addSkills.map((skill) => skill.name);
        this.candidateForm.get('skills_text')?.setValue(skillsText);

        // Réinitialiser FormData pour mettre à jour avec les compétences actuelles
        this.formData.delete('skills_text[]');
        skillsText.forEach((text) => {
          this.formData.append('skills_text[]', text);
        });

        // Ajouter un payload vide si aucune compétence n'est présente
        if (skillsText.length === 0) {
          this.formData.append('skills_text[]', '');
        }
        let skills_text = this.candidateForm.get('skills_text')?.value || [];

        // Réinitialiser FormData pour s'assurer que toutes les compétences sont bien mises à jour
        this.formData.delete('skills_text[]');
        if (Array.isArray(skills_text)) {
          skills_text.forEach((text) => {
            this.formData.append('skills_text[]', text);
          });
        } else {
          console.error('Skills_text is not an array:', skills_text);
        }
        if (skills_text.length === 0) {
          this.formData.append('skills_text[]', '');
        }

        let softSkills = this.candidateForm.get('softSkills')?.value || [];

        // Réinitialiser FormData pour mettre à jour avec les compétences actuelles
        this.formData.delete('softSkills[]');
        softSkills.forEach((text:any) => {
          this.formData.append('softSkills[]', text);
        });

        // Ajouter un payload vide si aucune compétence n'est présente
        if (softSkills.length === 0) {
          this.formData.append('softSkills[]', '');
        }
      
        // Réinitialiser FormData pour s'assurer que toutes les compétences sont bien mises à jour
        this.formData.delete('softSkills[]');
        if (Array.isArray(softSkills)) {
          softSkills.forEach((text) => {
            this.formData.append('softSkills[]', text);
          });
        } else {
          console.error('Skills_text is not an array:', softSkills);
        }
        if (softSkills.length === 0) {
          this.formData.append('softSkills[]', '');
        } 
        
        this.candidateForm
          .get('desired_contract')
          ?.setValue(this.getCheckedItems());
        this.formData.append(
          'first_name',
          this.candidateForm.get('first_name')?.value
        );
        this.formData.append(
          'last_name',
          this.candidateForm.get('last_name')?.value
        );
        if (this.candidateForm.get('cv')?.value == 'null') {
          this.formData.append('cv', '');
        } else {
          this.formData.append('cv', this.candidateForm.get('cv')?.value);
        }
        this.formData.append(
          'current_country',
          this.candidateForm.get('current_country')?.value
        );
        this.formData.append(
          'open_for_mission',
          this.candidateForm.get('open_for_mission')?.value
        );
        this.formData.append(
          'availability',
          this.candidateForm.get('availability')?.value
            ? this.candidateForm.get('availability')?.value
            : []
        );
        this.formData.append(
          'desired_workstation',
          this.candidateForm.get('desired_workstation')?.value
            ? this.candidateForm.get('desired_workstation')?.value
            : ''
        );
        this.formData.append(
          'savoir_faire',
          this.candidateForm.get('savoir_faire')?.value
            ? this.candidateForm.get('savoir_faire')?.value
            : ''
        );
        this.formData.append(
          'desired_salary',
          this.candidateForm.get('desired_salary')?.value
            ? this.candidateForm.get('desired_salary')?.value
            : ''
        );
        this.formData.append(
          'date_first_experience',
          this.candidateForm.get('date_first_experience')?.value
            ? this.candidateForm.get('date_first_experience')?.value
            : ''
        );
        this.formData.append(
          'tjm',
          this.candidateForm.get('tjm')?.value
            ? this.candidateForm.get('tjm')?.value
            : ''
        );
        this.formData.append(
          'linkedin_link',
          this.candidateForm.get('linkedin_link')?.value
        );
        this.formData.append(
          'phone_number',
          this.candidateForm.get('phone_number')?.value
        );
        this.formData.append('email', this.candidateForm.get('email')?.value);
        const emailProESNValue = this.candidateForm.get('emailProESN')?.value;
        const emailProClientValue =
          this.candidateForm.get('emailProClient')?.value;

        if (emailProESNValue !== null && emailProESNValue !== undefined) {
          this.formData.append('emailProESN', emailProESNValue);
        }

        if (emailProClientValue !== null && emailProClientValue !== undefined) {
          this.formData.append('emailProClient', emailProClientValue);
        }

        if (this.imageChanged) {
          this.formData.append('image_url', this.file);
        }
        if(this.dataDissocier){
          this.dissocier()
        }
        this.spinner.show();
        this.candidatService
          .updateCandidate(this.idCandidat, this.formData)
          .pipe()
          .subscribe({
            next: (res) => {
              this.spinner.hide();
              this.formData = new FormData();
              if (res.status == 200) {
                // this.addSkillsCandidat();
                console.log("res update candidat",res)
                this.toastrService.success(successEditProfile);
                this.fileName = '';
                this.getCandidateById();
                this.getSkills();
                this.navigateToProfilCandidat(this.idCandidat);
              }
              this.showSkills = 'skills';
            },
            error: (err) => {
              this.spinner.hide();
              this.formData = new FormData();
              if (err?.error) {
                if (err.error?.message['email'])
                  this.toastrService.error(emailExist);
                else if (
                  err.error?.message?.phone_number?.[0] ===
                  'The phone number has already been taken.'
                )
                  this.toastrService.error(phoneNumberExist);
                else if (
                  err.error?.message?.phone_number?.[0] ===
                  "Le numéro du téléphone n'est pas valide."
                )
                  this.toastrService.error('Numéro de téléphone est invalide');
                else if (err.error?.message['linkedin_link'])
                  this.toastrService.error(linkdinExist);
                else {
                  if (err.error?.message['emailProESN']) {
                    this.toastrService.error('Email professionnel ESN existe');
                  }

                  if (err.error?.message['emailProClient']) {
                    this.toastrService.error(
                      'Email professionnel client final existe'
                    );
                  }
                }
                if (
                  err.error?.message ===
                  'The name of diploma has already been taken.'
                ) {
                  this.toastrService.error(
                    'Attention: Vous avez saisi deux fois la même diplome'
                  );
                } else if (
                  err.error?.message ===
                  'The name of certificat has already been taken.'
                ) {
                  this.toastrService.error(
                    'Attention: Vous avez saisi deux fois la même certification'
                  );
                }
              }
            },
          });
      } else {
        this.scrollToError();
        this.toastrService.error(checkRequiredInputs);
      }
    } else {
      this.toastrService.info(noThingChanged);
    }
  }

  setStars(langue: FormGroup, stars: number) {
    langue?.get('stars')?.setValue(stars);
  }
  validateLanguages(event: any) {
    console.log('before event ', event);

    this.languages = this.languages.filter((item: any) => {
      if (item.id == event.id) {
        item.isFiltered = true;
        console.log('after event ', item);
      }
      return item;
    });
    console.log('langues : ', this.languages);


     const languesArray = this.candidateForm.get('langue_user') as FormArray;
    const languages = languesArray.value.filter((langue:any)=> 
    {
      if(!langue.isFiltered)
      return langue.name
  
  }
  )

    .map((langue: any) => langue.name);

    const duplicateLanguage = languages.find(
      (value: any, index: any, array: any) => array.indexOf(value) !== index
    );

    if (duplicateLanguage) {
      languesArray.setErrors({ duplicateLanguage: true });
    } else {
      languesArray.setErrors(null);
    }
    
  }
  get languesControls(): FormGroup<any>[] {
    return (
      (this.candidateForm.get('langue_user') as FormArray)?.controls.map(
        (control) => control as FormGroup
      ) || []
    );
  }
  onNgSelectChange(event: any) {
    console.log(
      'Ng-Select Value:',
      this.candidateForm.get('sub_category_id')?.value
    );
  }
  /* Custom validator function : requirement CV or Linkedin  */
  setValidatorRequirements() {
    const cvControl = this.candidateForm.get('cv');
    const linkedinControl = this.candidateForm.get('linkedin_link');

    if (!cvControl!.value && !linkedinControl!.value) {
      cvControl!.setValidators([Validators.required]);
      linkedinControl!.setValidators([
        Validators.required,
        Validators.pattern(linkedInRegExp),
      ]);
    } else if (cvControl!.value) {
      linkedinControl!.setValidators([Validators.pattern(linkedInRegExp)]);
    } else if (linkedinControl!.value) {
      cvControl!.clearValidators();
      linkedinControl!.setValidators([
        Validators.required,
        Validators.pattern(linkedInRegExp),
      ]);
    }

    cvControl!.updateValueAndValidity();
    linkedinControl!.updateValueAndValidity();
  }
  // islink:boolean=false
  // verifurllinkedin_link() {
  //   const url = this.candidateForm.get('linkedin_link')?.value;
  //   this.islink = url && url.includes('https://www.linkedin.com/in/');
  // }
  /* get phone number */
  changePhoneNumber(event: any) {
    if (event.phoneNumber)
      this.candidateForm.get('phone_number')?.setValue(event.phoneNumber);
  }
  onSelectChange(event: any, controlName: string) {
    this.candidateForm.get(controlName)?.setValue(event.target.value);

    const selectedMonth = this.candidateForm.get('monthControl')?.value;
    const selectedYear = this.candidateForm.get('yearControl')?.value;

    if (selectedMonth !== null && selectedYear !== null) {
    }
  }

  navigateToCvCandidat(idCandidat: number) {
    this.router.navigate([
      '/acceuil/candidats/Cv-candidat',
      { idCandidat: idCandidat },
    ]);
  }
  navigateToProfilCandidat(idCandidat?: number) {
    this.router.navigate([
      '/acceuil/candidats/profil-candidat',
      { idCandidat: idCandidat },
    ]);
  }
  /* calculate experience */
  show_experience: boolean = false;
  show_error: boolean = false;
  experienceText: any;
  calculateExperience() {
    const dateInput = this.candidateForm.get('date_first_experience')?.value;
    if (!dateInput) {
      this.experienceText = ''; // Réinitialiser le texte de l'expérience
      this.isexp = false; // Masquer l'élément si nécessaire
      return;
    } else {
      this.isexp = true;
    }

    let currentDate: any = new Date();
    let startDateObj: any = new Date(
      this.candidateForm.value.date_first_experience
    );
    let timeDifference = currentDate - startDateObj;
    if (timeDifference > 0) {
      this.show_experience = true;
    } else this.show_experience = false;
    let years = Math.floor(timeDifference / (365.25 * 24 * 60 * 60 * 1000));
    let months = Math.floor(
      (timeDifference % (365.25 * 24 * 60 * 60 * 1000)) /
        (30.44 * 24 * 60 * 60 * 1000)
    );
    this.experienceText =
      years > 0 && months > 0
        ? `${years} ans et ${months} mois`
        : years == 0
        ? `${months} mois`
        : months == 0
        ? `${years} ans`
        : '';
    // this.updateExperienceText();
  }

  isChosenDateValid!: boolean;

  // selectDate() {
  //   const today = new Date();
  //   const chosen = new Date(this.candidateForm.value.date_first_experience);
  //   this.isChosenDateValid = chosen > today;
  // }
  selectDate() {
    const today = new Date();
    const chosenDateString = this.candidateForm.value.date_first_experience;
    const chosen = new Date(chosenDateString);

    if (isNaN(chosen.getTime())) {
      // Handle invalid date scenario
      this.isChosenDateValid = false;
      console.error('Invalid date chosen.');
    } else {
      this.isChosenDateValid = chosen > today;
    }
  }
  selectedDate: string | null = null;
  changeExperience(event: any) {
    this.experience = event.target.checked ? true : false;
    localStorage.setItem('experience', JSON.stringify(this.experience));
    this.show_experience = !this.experience;

    if (this.experience) {
      this.candidateForm.get('date_first_experience')?.setValue(null);
      localStorage.removeItem('experienceText');
      this.experienceText = '---';
      this.show_experience = false;

      this.selectedDate = this.candidateForm.value.date_first_experience;
      this.candidateForm.patchValue({ date_first_experience: '' });
      this.isChosenDateValid = false;
      localStorage.setItem('date_first_experience', '');
      if (this.selectedDate) {
        const formattedDate = this.formatDateForInput(this.selectedDate);
        this.candidateForm.patchValue({ date_first_experience: formattedDate });
        const chosen = new Date(formattedDate);
        const today = new Date();
        this.isChosenDateValid = chosen > today;
        localStorage.setItem('date_first_experience', formattedDate);
      } else {
        const chosenDateString = this.candidateForm.value.date_first_experience;
        const chosen = new Date(chosenDateString);
        const today = new Date();
        this.isChosenDateValid = chosen > today;
        localStorage.setItem('date_first_experience', chosenDateString);
      }
    }
  }

  // updateExperienceText() {
  //   localStorage.setItem('experienceText', this.experienceText);
  // }

  formatDateForInput(dateString: string): string {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }
  /* *********************************** mes competences ******************** */
  /* get pagination items */
  getItems(event?: any) {
    if (
      (event && event.startIndex != this.startIndex) ||
      this.endIndex != event.endIndex ||
      this.itemsPerPage != event.itemsPerPage ||
      this.currentPage != event.currentPage
    ) {
      this.startIndex = event.startIndex;
      this.endIndex = event.endIndex;
      (this.itemsPerPage = event.itemsPerPage),
        (this.currentPage = event.currentPage);
         if( (this.startIndex !=0 ||this.endIndex !=5) || this.pagination == true){
          this.pagination = true
          this.getListModules();
        }
    }
  }
  /* handle check candidates from selected list  after pagination */
  handleCheck() {
    for (const iterator of this.listCategories) {
      for (const sub of iterator.sub_category) {
        for (const candidat of this.checkedSubCat) {
          if (sub.id == candidat.id) {
            candidat.selected = sub.selected;
          }
        }
      }
    }
  }
  getSkills() {
    this.candidatService
      .getSkillsCandidate(this.idCandidat)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          this.skillsCandidat = res.data.categoriesWithSubCategoriesAndSkills;
          this.totalItems = res.data.count;

          // Reset SkillsStars array
          // this.SkillsStars = [];
          // this.flattenedStars = [];
          // this.maxSubSkills = 0;

          // Get existing ratings from the form
          const existingRatings = this.candidateForm.get('skills')?.value || [];
          // Iterate through categories, subcategories, and skills to extract stars
          // this.skillsCandidat.forEach((category: any) => {
          //   category.sub_category.forEach((subCategory: any) => {
          //     this.maxSubSkills = Math.max(
          //       this.maxSubSkills,
          //       subCategory.skills.length
          //     );
          //     subCategory.skills.forEach((skill: any) => {
          //       const skillId = skill.id.toString();
          //       // Get existing rating for the skill
          //       const existingRating = existingRatings.find(
          //         (rating: any) => rating.sub_category_id === skillId
          //       );
          //       const stars = existingRating
          //         ? existingRating.stars
          //         : parseInt(skill.stars, 10) || 0;
          //       this.SkillsStars.push([stars]);
          //     });
          //   });
          // });

          // Flatten the array for display/logging purposes
          // this.flattenedStars = this.SkillsStars.flat();

          if (!this.isAnnuler)
            this.skillsCandidat.forEach((item: any) => {
              if (item.sub_category.length > 0)
                item.sub_category.forEach((element: any) => {
                  if (!this.arrSkilles.includes(element.id))
                    this.arrSkilles.push(element.id);
                  const subCategoryIdControl =
                    this.candidateForm.get('sub_category_id');
                  if (this.isAnnuler)
                    this.arrSkilles = this.arrSkilles.filter(
                      (item: any) => item != this.idSubCategory
                    );
                  if (subCategoryIdControl) {
                    subCategoryIdControl.setValue(this.arrSkilles);
                  }
                });
            });

          console.log('skills of skillsList ', this.arrSkilles);
        },
        error: () => {
          this.toastrService.error(serverError);
        },
      });
  }

  updateSelectedSkills(selectedCategories: any[]) {
    // Assurez-vous que les compétences sélectionnées sont bien dans la liste des compétences disponibles
    this.candidateForm.get('sub_category_id')?.setValue(selectedCategories);
  }

  /* get list of moduls */
  getListModules() {
    this.moduleService
      .getListCategoriesCnadidat()
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          this.categories = res.data.data;
          this.categories.map((el: any) => {
            el.sub_category.map((subCat: any) => {
              subCat.selected = false;
            });
          });
          this.listCategories = res.data.data.slice(
            this.startIndex,
            this.endIndex
          );
          this.totalItems = res.data.total;
          this.getSkillsCandidate();
        },
        error: () => {},
      });
  }
  getListSkills() {
    this.jobRequestService
      .getSkills()
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          this.skillsList = res.data;
        },
        error: () => {},
      });
  }
  getSkillsCandidate() {
    this.candidatService
      .getSkillsCandidate(this.idCandidat)
      .pipe()
      .subscribe({
        next: (res) => {
          this.skillsCandidate = res.data.categoriesWithSubCategoriesAndSkills;
          const subCategoryIds = this.skillsCandidate.flatMap((item: any) =>
            item.sub_category.map((sub: any) => {
              return sub.id;
            })
          );
          this.selectedSubcategoryIds = subCategoryIds;
          this.categories?.forEach((category: any) => {
            category.sub_category.forEach((subcategory: any) => {
              if (subCategoryIds.includes(subcategory.id)) {
                subcategory.selected = true;
              }
              // else {
              //   subcategory.selected = false;
              // }
            });
          });
          /* this.listCategories */
          this.getSubCategoryNamesByIds(subCategoryIds, this.categories);
        },
        error: (err) => {},
      });
  }
  onCheckboxChange(category: any, subcategory: any, event: any) {
    console.log('subcategory', subcategory);

    subcategory.selected = event.target.checked;
    // this.categories.map((el:any)=>{
    //   el.sub_category.map((subCat:any)=>{
    //     if(subCat.id==subcategory.id){

    //     }
    //   })
    // })
    console.log('categories', this.categories);

    this.updateSubSkillSlection();
  }
  checkedSubCat: any = [];
  updateSubSkillSlection() {
    let selectedSubcategoryNames = [];
    this.selectedSubcategoryIds = [];
    for (const iterator of this.categories) {
      for (const cat of iterator.sub_category) {
        if (cat.selected) {
          this.checkedSubCat.push(cat);
          selectedSubcategoryNames.push(cat.name_FR);

          if (!this.selectedSubcategoryIds.includes(cat.id)) {
            this.selectedSubcategoryIds.push(cat.id);
          }
        }
      }
    }
    console.log('eeeeeeeeeeeeeeeeeeeeeeeeeeeeeee', this.checkedSubCat);

    console.log('selectedSubcategories', this.selectedSubcategories);

    this.selectedSubcategories = [...selectedSubcategoryNames];
  }

  getSubCategoryNamesByIds(ids: number[], data: any[]) {
    const subCategoryNames: string[] = [];
    if (Array.isArray(data)) {
      for (let category of data) {
        for (let subCategory of category.sub_category) {
          if (ids.includes(subCategory.id)) {
            subCategoryNames.push(subCategory.name_FR);
          }
        }
      }
    }
    this.selectedSubcategories = [...subCategoryNames];
    this.handleCheck();
  }

  onInputChange(data: any) {
    this.candidateForm.get('phone_number')?.setValue(data.phoneNumber);
  }
  onSelectChangeSkills(event: any) {
    this.selectedSubcategoryIds = [];
    for (const iterator of this.categories) {
      for (const subCat of iterator.sub_category) {
        if (event.includes(subCat.name_FR)) {
          this.selectedSubcategoryIds.push(subCat.id);
          subCat.selected = true;
        } else {
          subCat.selected = false;
        }
      }
    }
  }

  /* get Ids From Names */
  getIdsFromNames(names: string[], data: any[]): number[] {
    const itemIds: number[] = [];
    for (const category of data) {
      for (const subCategory of category.sub_category) {
        if (names.includes(subCategory.name_FR)) {
          itemIds.push(subCategory.id);
        }
      }
    }
    return itemIds;
  }
  /* key inter number */
  keyPress(event: any) {
    let pattern = /^[\d]$/;
    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode !== 8 && pattern && !pattern.test(inputChar)) {
      event.preventDefault();
    }
    return pattern;
  }

  deletedSkills: string[] = [];

  // setRating(i: any, j: any, stars: any) {
  //   console.log('skill_Id : ', i, 'sub_category_id : ', j, 'stars : ', stars);
  //   console.log(
  //     'skillsCandidat',
  //     this.skillsCandidat[i].sub_category[j].skills[0].stars
  //   );
  //   this.skillsCandidat[i].sub_category[j].skills[0].stars = stars;

  // }

  // isStarGold(i: number, j: number, k: number): boolean {
  //   const flattenedIndex = i * this.maxSubSkills + j;
  //   return this.flattenedStars[flattenedIndex] >= k;
  // }

  getSkillName(index: number): string {
    const skill = this.skillsCandidat[index];
    return skill ? skill.name_FR : '';
  }

  getSubSkills(index: number): any[] {
    const skill = this.skillsCandidat[index];
    return skill ? skill.sub_category : [];
  }

  removeSkills: any[] = [];
  items: any[] = [];

  getItemSkill(event: any) {
    this.items = this.candidateForm.get('sub_category_id')?.value;
    console.log('items of ', this.items);
    console.log('skillsCandidat of ', this.skillsCandidat);
    let id = this.items.at(-1);
    console.log('item of sub_category_id ', id);

    this.skillsAdd.push({
      sub_category_id: id,
      stars: null,
      user_id: this.idCandidat,
    });
    // console.log("skills of ",this.skillsAdd )
    this.skillsList.forEach((item: any) => {
      if (item.id === id) {
        if (item) {
          // console.log('item of category_id ', item.category_id);
          // this.moduleService.getCatId(item.category_id).subscribe((cat) => {
          //   console.log('item of cat ', cat.data);
          //   console.log(
          //     'item of cat ',
          //     this.skillsCandidat.filter((s: any) => s.id === item.category_id)
          //       .length > 0
          //   );
          this.moduleService.getCatId(item.category_id).subscribe((cat) => {
            if (
              this.skillsCandidat.filter((s: any) => s.id === item.category_id)
                .length > 0
            ) {
              item['skills'] = [
                { sub_category_id: id, stars: null, user_id: this.idCandidat },
              ];
              this.skillsCandidat
                // .filter((s: any) => s.id === item.category_id)[0]
                // .sub_category.push(item);
                .filter((s: any) => s.id === item.category_id)[0]
                .sub_category.find((sub: any) => sub.id === item.id) ===
                undefined &&
                this.skillsCandidat
                  .filter((s: any) => s.id === item.category_id)[0]
                  .sub_category.push(item);
            } else {
              cat.data.sub_category = cat.data.sub_category
                .filter(
                  (sub: any, index: number, self: any[]) =>
                    index === self.findIndex((t: any) => t.id === sub.id)
                )
                .filter((sub: any) => sub.id === id)
                .map((item: any) => {
                  item['skills'] = [
                    {
                      sub_category_id: id,
                      stars: null,
                      user_id: this.idCandidat,
                    },
                  ];
                  return item;
                });
              console.log('item of sub_category_id ', cat.data);
              this.skillsCandidat.push(cat.data);
            }

            console.log('skillsCandidat of ', this.skillsCandidat);
          });
        }
      }
    });

    if (id != undefined) {
      this.removeSkills.push(id);
    }
  }
  add(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    console.log('event value', value);
    // this.listSkills?.push(value)
    // Add our skill
    if (this.isFormatCompetence(value)) {
      if (!this.addSkills?.some((item) => item.name === value)) {
        this.updateSkills.push(value);
        this.addSkills?.push({ name: value });
        this.isFormatCompetenceAdmin = false;
      }
    } else {
      this.isFormatCompetenceAdmin = true;
    }
    // Clear the input value
    event.chipInput!.clear();
  }

  isFormatCompetence(competence: string): boolean {
    // Vérifie que la compétence commence par une lettre et ne contient que des lettres et des chiffres
    const pattern = /^[a-zA-Z][a-zA-Z0-9]*$/;
    return pattern.test(competence);
  }
  remove(skill: addSkill): void {
    const index = this.addSkills.indexOf(skill);

    if (index >= 0) {
      this.addSkills.splice(index, 1);

      this.updateSkillsText();

      this.formData.delete('skills_text[]');

      this.addSkills.forEach((skill) => {
        this.formData.append('skills_text[]', skill.name);
      });

      if (this.addSkills.length === 0) {
        this.formData.append('skills_text[]', '');
      }

      this.announcer.announce(`Removed ${skill.name}`);
    }
  }
  updateSkillsText(): void {
    const skillsText = this.addSkills.map((skill) => skill.name);
    this.candidateForm.get('skills_text')?.setValue(skillsText);

    this.formData.delete('skills_text[]');
    skillsText.forEach((text) => {
      this.formData.append('skills_text[]', text);
    });

    if (skillsText.length === 0) {
      this.formData.append('skills_text[]', '');
    }
  }

  edit(skill: addSkill, event: MatChipEditedEvent) {
    const value = event.value.trim();

    if (!value) {
      this.remove(skill);
      return;
    }

    const index = this.addSkills.indexOf(skill);
    if (index >= 0) {
      this.addSkills[index].name = value;
    }
  }

  resetCV() {
    const inputFile = document.getElementById('cv') as HTMLInputElement;
    if (inputFile) {
      inputFile.value = '';
    }
    this.fileExtention = '';
    this.fileError = '';
    this.candidateForm.get('cv')?.setValue('');
    console.log('clear cv', this.candidateForm.get('cv')?.value);
    this.fileName = '';
    this.setValidatorRequirements();
    this.existCv = true;
  }
  onCountryChange(mode: string): void {
    this.candidateForm.get('mobility')?.enable();
    this.countries = this.candidateForm.get('destination_country')?.value;
    this.countries = this.countries.map(
      (item) => this.paysList.filter((it) => it.name === item)[0]
    );
    this.stateList.length = 0;
    this.mobilities = this.candidateForm.get('mobility')?.value;

    this.countries.forEach((item) => {
      var after: any[] = State.getStatesOfCountry(item?.isoCode);
      if (after.length == 0) after.push({ name: 'Tout le pays' });
      this.stateList.push(after);
    });
    this.stateList = this.stateList.flat();
    for (const iterator of this.stateList) {
      iterator.name = iterator.name.replace(' Governorate', '');
    }
    this.stateList = this.stateList.map((item: any) => item.name);
    if (this.mobilities != null && this.mobilities.length > 0) {
      let differentItemsArray1: any[] = [];
      differentItemsArray1 = this.mobilities.filter(
        (item) => !this.stateList.includes(item)
      );
      var differentItemsArray2: any[] = [];
      differentItemsArray2 = this.mobilities.filter(
        (item) => !differentItemsArray1.includes(item)
      );
      this.candidateForm.get('mobility')?.setValue(differentItemsArray2);
    }

    if (this.stateList.length == 0) {
      this.stateList = [];
      mode == 'add';
      if (this.countries.length == 0) {
        this.candidateForm.get('mobility')?.disable();
        this.candidateForm.get('mobility')?.setValue(null);
      }
    }
  }

  onMobilityChange(mode: string) {
    this.mobilities = this.candidateForm.get('mobility')?.value;
    let paysLists: any[] = [];
    this.countries = this.candidateForm.get('destination_country')?.value;
    this.countries = this.countries.filter(async (item: any) => {
      paysLists.push(this.paysList.filter((it) => it.name === item)[0]);
    });
    let stateListAfter: any[] = [];
    paysLists.forEach((item) => {
      State.getStatesOfCountry(item.isoCode).forEach((item) => {
        stateListAfter.push(item.name); ////  }
      });
    });
    console.log(
      'stateList in change mobilities mobilities : ',
      this.mobilities
    );
    console.log(
      'stateList in change mobilities stateListAfter  : ',
      stateListAfter
    );
    this.stateList = [...new Set(this.mobilities.concat(stateListAfter))];
    console.log('stateList in change mobilities : ', this.stateList);
    this.mobilities = this.candidateForm.get('mobility')?.value;

    if (this.countries.length != 0) {
      if (this.mobilities.length === 0 && this.stateList.length === 0) {
        this.onCountryChange('add');
      }
    }
  }

  findDuplicates(array1: any, array2: any) {
    let occurrences: any[] = [];
    let duplicates: any = [];
    for (let element of array1) {
      occurrences[element] = (occurrences[element] || 0) + 1;
    }
    for (let element of array2) {
      if (occurrences[element] && !duplicates.includes(element)) {
        duplicates.push(element);
      }
    }
    return duplicates;
  }

  /* unsubscribe from api */
  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }
  checkeddatefin:any[]=[]

  onCurrentPositionChange(index: number, event: any) {
    
    const isChecked = (event.target as HTMLInputElement).checked;
    const formGroup = this.experiencesControls.at(index) as FormGroup;
    if (isChecked) {
      formGroup.get('end_date')?.setValue(null);
      this.checkeddatefin[index] = true;
    } else {
      formGroup.get('end_date')?.setValue(null);
      this.checkeddatefin[index] = false;
    }
    this.calculatePeriod(index,this.checkeddatefin[index]);
  }
  getTodayDatelast(): string {
    return this.datePipe.transform(new Date(), 'dd/MM/yy')!;
  } 
  formatDate(date: Date): string {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  parseDate(dateStr: string | null): Date | null {
    if (!dateStr) return null;
    const [day, month, year] = dateStr.split('/');
    return new Date(+year, +month - 1, +day); // Les mois commencent à 0 en JavaScript
  }
  calculatePeriod(index: number,current_position:boolean) {
    const experiences = this.candidateForm.get('prof_experience') as FormArray;
    const experience = experiences.at(index) as FormGroup;
  
    const startDate = experience.get('start_date')?.value;
    let endDate = current_position ? this.today : experience.get('end_date')?.value;
  
    if (startDate && new Date(startDate).getFullYear() < 2000) {
      experience.get('start_date')?.setErrors({
        invalidDatesart: true,
      });
      return;
    } else {
      experience.get('start_date')?.setErrors(null);
    }
    if (endDate && new Date(endDate).getFullYear() < 2000) {
      experience.get('end_date')?.setErrors({ invalidDate: true });
      return;
    } else {
      experience.get('end_date')?.setErrors(null);
    }
    if (startDate && endDate && new Date(startDate) > new Date(endDate)) {
      experience.setErrors({ invalidDateRange: true });
      return;
    } else {
      experience.setErrors(null);
    }
  
    if (experience.get('current_position')?.value) {
      endDate = new Date(endDate);
      endDate.setDate(endDate.getDate() - 1);
    }
  
    const daysDifference = this.calculateDateDifference(startDate, endDate);
    const periodObject = this.calculateYearsMonthsDays(daysDifference);
    const periodString = `${periodObject.years} ans, ${periodObject.months} mois, ${periodObject.days} jours`;
    console.log("periodString : ", periodString);
    experience.get('current_position')?.enable();
  
    experience.get('period')?.setValue(periodString);
  }
 

  getPeriod(start: Date, end: Date): string {
    const diffTime = Math.abs(end.getTime() - start.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    const years = Math.floor(diffDays / 365);
    const months = Math.floor((diffDays % 365) / 30);
    const days = diffDays - years * 365 - months * 30;

    return `${years} an(s), ${months} mois`;
  }
}
