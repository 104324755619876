import { Component } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { ActivatedRoute, Router } from '@angular/router';
import { CandidatService } from '../../../../core/services/candidat/candidat.service';
import { Subject, takeUntil } from 'rxjs';
import { serverError } from '../../../../core/models/messages';
import { ToastrService } from 'ngx-toastr';
import { environment } from '../../../../../environments/environment';
import * as saveAs from 'file-saver';
import { CvType } from 'src/app/core/models/enums/cv-type.enum';

@Component({
  selector: 'app-cv-candidat',
  templateUrl: './cv-candidat.component.html',
  styleUrls: ['./cv-candidat.component.css'],
})
export class CvCandidatComponent {
  constructor(
    private spinner: NgxSpinnerService,
    private candidatService: CandidatService,
    private activatedRoute: ActivatedRoute,
    private toastrService: ToastrService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  endIndex: number = 5;
  idCandidat: any;
  selectedCandidate: any;
  totalItems: any;
  skillsCandidat: any;
  cvType: string | undefined;
  private unsubscribeAll: Subject<void> = new Subject();
  /* string */
  url: string = environment.baseUrl;
  baseUrl_Company = environment.baseUrlGaleryCompany;
  cvTypeEnum = CvType

  ngOnInit(): void {
    this.spinner.show();
    setTimeout(() => {
      // Hide the spinner after some time (or after data is loaded)
      this.spinner.hide();
    }, 5000); // Adjust the delay as needed
    this.getRoutingParams();
    this.getSkills();
  }

  getRoutingParams() {

    this.route.params.subscribe(params => {
      this.idCandidat = params['idCandidat'];
      this.getCandidateById()
    });

    if (history.state && history.state.type) {
      const type = history.state.type
      if (type === this.cvTypeEnum.WEGESTU || type === this.cvTypeEnum.ESN) {
        this.cvType = type;
      }
    } 
  }
  formatPeriod(period: string): string {
    if (!period) return '';
  
    const matches = period.match(/(\d+) ans, (\d+) mois, (\d+) jours/);
    if (!matches) return period;
  
    const years = parseInt(matches[1], 10);
    const months = parseInt(matches[2], 10);
  
    if (years === 0 && months === 0) {
      return `moins d'un mois`;
    } else {
      let result = '';
  
      if (years > 0) {
        result += `${years} An${years > 1 ? 's' : ''}`;
      }
      if (months > 0) {
        if (result.length > 0) result += ', ';
        result += `${months} Mois`;
      }
  
      return result;
    }
  }
  capitalizeFirstLetter(string:any) {
    if (!string) return string;
    return string.charAt(0).toUpperCase() + string.slice(1);
  } 
  getCandidateById() {
    this.spinner.show();
    this.candidatService
      .getUserById(this.idCandidat)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res: any) => {
          this.selectedCandidate = res.data[0];
          this.selectedCandidate.softSkills= this.selectedCandidate.softSkills.filter((skill:any) => skill !== null);
          console.log("selectedCandidate of ", this.selectedCandidate)
          this.spinner.hide();
        },
        error: () => {
          this.spinner.hide();
        },
      });
  }

  navigateToProfilCandidat(idCandidat?: number) {
    this.router.navigate([
      '/acceuil/candidats/profil-candidat',
      { idCandidat: idCandidat },
    ]);
  }
  getSkills() {
    this.candidatService
      .getSkillsCandidate(this.idCandidat)
      .pipe(takeUntil(this.unsubscribeAll))
      .subscribe({
        next: (res) => {
          this.skillsCandidat = res.data.categoriesWithSubCategoriesAndSkills
            .map((category:any) => ({
              ...category,
              sub_category: category.sub_category
                .map((subCategory:any) => ({
                  ...subCategory,
                  skills: subCategory.skills.filter((skill:any) => skill.checked === '1')
                }))
                .filter((subCategory:any) => subCategory.skills.length > 0)
            }))
            .filter((category:any) => category.sub_category.length > 0);

          this.totalItems = res.data.count;
        },
        error: () => {
          this.toastrService.error(serverError);
        },
      });
  }
  exportToPDF() {
    const element = document.getElementById('kt_app_content');

    if (element) {
      this.spinner.show();
      html2canvas(element).then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF();
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
        pdf.save('export_Cv.pdf');
        this.spinner.hide();
      });
    } else {
      console.error('Element not found!');
    }
  }
  NavigateToUpdateCandidat() {
    this.router.navigate([
      '/acceuil/candidats/profil-candidat/update',
      { idCandidat: this.idCandidat },
    ]);
  }

  /**
  * Download CV.
  *  
  * @param {string} cvType - The type of CV to export (e.g., WEGESTU or ESN).
  * @param {number} isAnonymous - Whether to export an anonymous version of the CV (1 : not anonymous, 2 : anonymous) .
  * @returns {void}
  */
  downloadCV(cvType : string | undefined, isAnonymous: number){   
    if(cvType === this.cvTypeEnum.WEGESTU){
      this.exportCVCandidate(isAnonymous, null)
    }else{
      this.exportCVCandidate(isAnonymous, this.selectedCandidate?.ESN_id)
    }
}
// @TOFIX: duplicated
/**
* Exports the CV of a candidate based on the specified type and company ID.
*
* @param {number} type - The type of CV to export (e.g., 1 for standard, 2 for anonymous)
* @param {number | null} idCompany - The ID of the company for which the CV is being exported.
*                          Use `null` if no specific company is associated.
*/
exportCVCandidate(type: number, idCompany : number | null) {
  const extension = ".pdf"
  this.spinner.show()
  this.candidatService.exportCVCandidate(this.idCandidat, type, idCompany).subscribe({
  next: (res: any) => {
    this.spinner.hide();
    const blob = new Blob([res], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
    let name = `${this.selectedCandidate?.last_name}-${this.selectedCandidate?.first_name}`
    if(idCompany){
      name = name.concat('-CvESN')
    }else{
      name = name.concat('-CvWegestu')
    }
    if(type === 2){
      name = name.concat('-Anonyme')
    }
    name = name.concat(extension)

    saveAs(blob, name);
      this.toastrService.success('CV téléchargé avec succès');
  },
  error: () => {
    this.spinner.hide();
    this.toastrService.error(serverError);
  },
  });
}
  /* unsubscribe from api */
  ngOnDestroy() {
    this.unsubscribeAll.next();
    this.unsubscribeAll.complete();
  }
}
